import React, {PropsWithChildren} from 'react';
import classnames from 'classnames';
import {Col, Container, Nav, Navbar, Offcanvas, Row} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import logo from '../../../assets/images/logo32.png'
import BottomBar from '../../Mobile/BottomBar/BottomBar';
import Menu from '../../Mobile/Menu/Menu';

export default function ApplicationLayout({children, className, style}: PropsWithChildren<DefaultProps>) {

    return (
        <div className={classnames("application-layout", className)} style={{paddingBottom: '80px' ,...style}}>
            <Navbar variant="dark" className="default-background mb-4" expand="lg" >
                <Container>
                    <Navbar.Brand>
                        <img src={logo} alt="Trinity App Logo" width="32" height={32} />
                        Trinity
                    </Navbar.Brand>
                </Container>
            </Navbar>
            <Row className="w-100 g-0">
                <Col xs={2} sm={2} lg={1} className="d-none d-md-block position-relative">
                    <Menu />
                </Col>
                <Col xs={12} sm={12} lg={6} xl={5}>
                    {children}
                </Col>
            </Row>

            <BottomBar />
        </div>
    )
}