import React from 'react';
import {Goal, Taxonomy} from '../../../../../db/types';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {routeGoalsList} from '../../../../../routes';
import useAppTitle from '../../../../../hooks/useAppTitle';
import OverviewGoal from '../../../../Common/Goal/OverviewGoal/OverviewGoal';


export default function OverviewGoalScreen() {
    const navigate = useNavigate();
    const {goal} = useLoaderData() as {goal: Goal, collections: Taxonomy[]};

    useAppTitle('Goals', 'Goal Overview');
    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            {goal && <OverviewGoal goal={goal} onBack={()=> {
                navigate(routeGoalsList("all"));
            }} />}
        </div>
    );
}