import {Requests} from '../../api/requests';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {sendRequest} from '../../api/frontend/api';


export default function makeEditTaxonomiesLoader(entity: string) {
    return async function editTaxonomiesLoader({params, request}) {
        if (!params._id) {
            return {taxonomy: null, editorType: params.type, routeContext: params.context};
        }

        const response = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.getTaxonomy,
            data: {_id: params._id}
        });

        return {taxonomy: response.data, editorType: params.type, entity: entity, routeContext: params.context, error: response.error};
    }
}

