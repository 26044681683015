import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';

export default async function editNotesLoader({params}) {

    if (!params._id) {
        return {note: null, editorType: params.type, routeContext: params.context};
    }


    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getNote,
        data: {_id: params._id}
    });

    return {note: response.data || null, editorType: params.type, routeContext: params.context, error: response.error};
}