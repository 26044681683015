export enum Environments {
    EXTENSION = 'extension',
    PWA = 'pwa'
}

export const getEnv = (): Environments => {
    if (typeof chrome !== 'undefined' && typeof chrome.runtime !== 'undefined') {
        return  Environments.EXTENSION
    }

    return Environments.PWA
}