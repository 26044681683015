import React from 'react';
import classnames from 'classnames';
import {getCityFromTimezone} from '../../../utils/common/timezones';
import DigitalClock from '../../Common/DigitalClock/DigitalClock';

import './LocationClock.scss';
import DefaultProps from '../../../types/DefaultProps';

export type Props = {
    timezone: string,
    userLocale: string,
} & DefaultProps;

const LocationDisplay = ({ timezone, userLocale, className, style }: Props) => {
    const timezoneAbbreviation = new Date(Date.now())
        .toLocaleTimeString(userLocale, {
            timeZone: timezone,
            timeZoneName: "short"
        })
        .split(" ")[2];

    return (
        <div className={classnames('location-clock', className)} style={style}>
            <div>
                <div className="title">
                    {timezone && <span className="city">{getCityFromTimezone(timezone)}</span>}
                    <span className="timezone-abbreviation">{timezoneAbbreviation}</span>
                </div>
                <DigitalClock timezone={timezone} userLocale={userLocale} />
            </div>
        </div>
    );
};

export default LocationDisplay;