import React, {ChangeEvent, useCallback, useState} from 'react';
import {Taxonomy} from '../db/types';
import {useResponseErrors} from './useResponseErrors';
import configs from '../configs';

export type Pointer = {value: null|string, type: 'init'|'search'|'collection'|'sort', limit?: number};


export type Props = {
    listCallback: (options: {query: string, collection: string, sort: string, pointer: Pointer}) => any;
    defaultSort: string,
}


export default function useListItemsWithSort<T>({listCallback, defaultSort}: Props) {
    const [items, setItems] = useState<T[]>([]);
    const [pointer, setPointer] = useState<Pointer>({value: null, type: 'init'});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [collection, setCollection] = useState('');
    const [query, setQuery] = useState('');
    const [sort, setSort] = useState(defaultSort);
    const [prevPointer, setPrevPointer] = useState<Pointer>({value: null, type: 'init'});


    const {RenderedErrors, addError, clearErrors, hasErrors} = useResponseErrors();

    const reset = useCallback(() => {
        setPointer({value: null, type: 'init'});
        setCollection('');
        setQuery('');
        setSort(defaultSort)
        setItems([])

    }, [items, pointer, query, collection, status])

    const refresh = useCallback(() => {
        setItems(() => []);
        setPointer({...prevPointer});
        onLoadMore();

    }, [items, pointer, query, collection, status, prevPointer])

    const onLoadMore = useCallback(async () => {
        if (isLoadingMore) {
            return;
        }

        clearErrors();
        let oldPointer = {...pointer};
        if (!collection && !query && sort == defaultSort && pointer.type !== 'init') {
            oldPointer = {type: 'init', value: null, limit: configs.application.cardsPerPage};
        }

        try {
            setIsLoadingMore(true);
            const {items: moreItems, pointer: newPointerValue} = await listCallback({query, collection, sort, pointer});
            setItems((items) => [...items, ...moreItems]);
            setPointer({...oldPointer, value: newPointerValue});
        } catch(error: any) {
            addError(error);
        }


        setIsLoadingMore(false);
    }, [items, pointer, query, collection, status, prevPointer])


    const onSearchChange = async (searchQuery: string) => {
        clearErrors();
        if (searchQuery && searchQuery?.length < 3) {
            setQuery(searchQuery);
            return;
        }

        setPrevPointer({...pointer});

        let pointerValue = pointer.value;

        if (pointer.type !== 'search' || searchQuery !== query) {
            pointerValue = null;
        }

        const oldPointer: Pointer = {type: 'search', value:  pointerValue, limit: configs.application.searchCardsPerPage}

        try {
            setIsLoadingMore(true);
            const {items: moreItems, pointer: newPointerValue} = await listCallback({query: searchQuery, collection, sort, pointer:oldPointer});


            if (query === searchQuery && pointer.type === 'search' && oldPointer.value != newPointerValue.value) {
                setItems([...items, ...moreItems]);
            } else {
                setItems([...moreItems]);
            }

            setPointer({...oldPointer, value: newPointerValue});
        } catch (error: any) {
            addError(error);
        }


        setQuery(searchQuery);
        setIsLoadingMore(false);
    }

    const onCollectionSelectChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const oldCollection = collection;
        clearErrors();
        setCollection(event.target.value);
        setPrevPointer({...pointer});

        let pointerValue = pointer.value;

        if (pointer.type !== 'collection' || oldCollection !== event.target.value) {
            pointerValue = null;
        }

        try {

            setIsLoadingMore(true);
            const oldPointer: Pointer = {type: 'collection', value: pointerValue, limit: configs.application.collectionCardsPerPage}
            const {items: newItems, pointer: newPointerValue} = await listCallback({query, collection: event.target.value, sort, pointer: oldPointer});

            if (pointer.type === 'collection' && event.target.value === oldCollection) {
                setItems([...items, ...newItems]);
            } else {
                setItems([...newItems]);
            }

            setPointer({...oldPointer, value: newPointerValue});
        } catch(error: any) {
            addError(error);
        }

        setIsLoadingMore(false);
    }

    const onSortChange = async (direction: 'asc' | 'desc') => {

        const oldSort = sort;
        const newSort = direction;

        clearErrors();
        setSort(newSort);
        setPrevPointer({...pointer});

        let pointerValue = pointer.value;

        if (pointer.type !== 'sort' || oldSort !== newSort) {
            pointerValue = null;
        }

        try {

            setIsLoadingMore(true);
            const oldPointer: Pointer = {type: 'sort', value: pointerValue, limit: configs.application.collectionCardsPerPage}
            const {items: newItems, pointer: newPointerValue} = await listCallback({query, collection: collection, sort: newSort, pointer: oldPointer});

            if (pointer.type === 'sort' && newSort === oldSort) {
                setItems([...items, ...newItems]);
            } else {
                setItems([...newItems]);
            }

            setPointer({...oldPointer, value: newPointerValue});
        } catch(error: any) {
            addError(error);
        }

        setIsLoadingMore(false);
    }


    return {
        RenderedErrors,
        addError,
        clearErrors,
        hasErrors,
        onLoadMore,
        pointer,
        setPointer,
        isLoadingMore,
        setIsLoadingMore,
        items,
        setItems,
        onSearchChange,
        query,
        onCollectionSelectChange,
        collection,
        onSortChange,
        sort,
        refresh,
        reset,
    }
}