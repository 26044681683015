import {PropsWithChildren, useEffect, useRef, useState} from 'react';
import classnames from 'classnames';

import './DigitalClock.scss';
import DefaultProps from '../../../types/DefaultProps';

const TimeColon = ({children, className, style}: PropsWithChildren<DefaultProps>) => {
    return <span className={classnames('time-colon', className)} style={style}> {children || ':'}</span>
}

const TimeString = ({children, className, style}: PropsWithChildren<DefaultProps>) => {
    return <div className={classnames('time-string', className)} style={style} > {children || ':'}</div>
}

export type Props = {
    timezone?: string,
    userLocale: string,
} & DefaultProps;

const LocationDisplay = ({ timezone, userLocale, className, style }: Props) => {
    const startTime = timezone ? new Date(Date.now()).toLocaleTimeString(userLocale, {
        timeZone: timezone
    }) : new Date(Date.now()).toLocaleTimeString(userLocale);
    const [time, setTime] = useState(startTime);
    //
    var n = useRef<number|null>(null);
    var t = useRef<number|null>(null);


    useEffect(() => {
        const timeOffset = 1000 - new Date(Date.now()).getMilliseconds();
        n.current = setTimeout(() => {
            t.current = setInterval(() => {
                setTime(
                    timezone ? new Date(Date.now()).toLocaleTimeString(userLocale, {
                        timeZone: timezone
                    }) : new Date(Date.now()).toLocaleTimeString(userLocale)
                );
            }, 1000);
        }, timeOffset);

        return function cleanup() {
            n.current = null;

            if (t.current) {
                clearInterval(t.current);
            }
        };
    }, [timezone]);

    const [timeDig, timeOfDay] = time.split(" ");
    const [hours, minutes, seconds] = timeDig.split(":");


    return (
        <TimeString className={classnames('digital-clock-default', className)} style={style}>
            {hours}<TimeColon />{minutes} {timeOfDay}
        </TimeString>
    );
};

export default LocationDisplay;