import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {getBackground} from '../../utils/common/requests';
import {CACHE_BACKGROUND} from '../../db/cache';

const domContainer = document.querySelector('#root');
const root = ReactDOM.createRoot(domContainer as Element);
import astronaut from './images/astronaut.jpg'
import {getWorker} from '../../utils/pwa/workers';

getWorker()

document.body.onload = () => {

    getBackground().then(url => {
        let currentUrl = astronaut;
        if (url) {
            currentUrl = url;
        }
        document.body.style.backgroundImage = `url(${currentUrl})`;
        window.localStorage.setItem(CACHE_BACKGROUND, currentUrl);
    }).catch(error => console.error(error));
}


root.render(
        <App />
);
