import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import { Response, Taxonomy} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeTaxonomiesList} from '../../../../../routes';
import TaxonomyCard from '../../../../Common/Cards/TaxonomyCard/TaxonomyCard';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteTaxonomyScreen() {
    const response = useLoaderData() as Response & {routeContext: string}
    const navigate = useNavigate();

    const entity = response.data?.type == 'collection' ? 'Collection' : 'Tag'
    useAppTitle(`${entity}s`, `Delete ${entity}`);


    const onDelete = useCallback(async () => {
       const r = await sendRequest({
           _id: getTimestampId(),
           generic: 'request',
           type: Requests.removeTaxonomy,
           data: {_id: response.data?._id}
       });

       return {response: r, route: routeTaxonomiesList(response.data?.type || 'collection', response.routeContext)}
    }, [response]);

    const onCancel = useCallback(() => {
        navigate(routeTaxonomiesList(response.data?.type || 'collection', response.routeContext));
    }, [response]);

    return (
        <DeleteScreen
            title="Delete Bookmark"
            onDelete={onDelete}
            onCancel={onCancel}
            text={`Are you sure you want to delete this ${response.data?.type}?`}
        >
            {response.data && <TaxonomyCard taxonomy={response.data as Taxonomy} panel={false} />}
        </DeleteScreen>
    );
}