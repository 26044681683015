import React, {useEffect, useState} from 'react';

import './BottomBar.scss';
import {Button, Offcanvas} from 'react-bootstrap';
import Icon from '../../Common/Icon/Icon';
import ApplicationMenu from '../../Common/ApplicationMenu/AppliatoinMenu';
import {useLocation, useNavigate} from 'react-router-dom';
import {
    routeBookmarksEdit,
    routeEventsEdit,
    routeGoalsEdit,
    routeNotesEdit,
    routeTasksEdit,
    routeWorldClocksEdit
} from '../../../routes';
import Menu from '../Menu/Menu';

export const getEditRoute = (path: string): string => {

    if (path.includes("/goals/")) {
        return routeGoalsEdit();
    }

    if (path.includes("/tasks/")) {
        return routeTasksEdit("text");
    }

    if (path.includes("/events/")) {
        return routeEventsEdit();
    }

    if (path.includes("/bookmarks/")) {
        return routeBookmarksEdit();
    }

    if (path.includes("/world-clocks/")) {
        return routeWorldClocksEdit();
    }

    return routeNotesEdit("text");
}

export default function BottomBar() {
    const [show, setShow] = useState(false);
    const [editRoute, setEditRoute] = useState(routeNotesEdit('text'));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {pathname} = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        setEditRoute(getEditRoute(pathname));
    }, [pathname]);

    return (
        <div className="bottom-bar d-flex d-md-none">
            <Offcanvas show={show} onHide={handleClose} placement="bottom" className="vh-100 default-background text-white">
                <Offcanvas.Header closeButton closeVariant="white">
                    <Offcanvas.Title>Menu</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Menu onClick={() => handleClose()} />
                </Offcanvas.Body>
            </Offcanvas>
            <div className="d-flex w-100 justify-content-between align-items-end">
                <div className="wrapper pt-2 ps-1">
                    <Button variant="dark" onClick={handleShow}>
                        <Icon type="ti-menu" />
                    </Button>
                </div>
                <Button
                    variant="dark"
                    style={{width: "15.9%"}}
                    className="btn-add mx-1 mb-1"
                    onClick={() => {
                        window.scroll(0, 0);
                        navigate(editRoute)
                    }}
                >
                    <Icon type="ti-plus" />
                </Button>
                <div className="wrapper d-flex justify-content-end pt-2 pe-1">
                    <Button variant="dark">
                        <Icon type="ti-search" />
                    </Button>
                    <Button variant="dark">
                        <Icon type="ti-bell" />
                    </Button>
                </div>
            </div>
            <div className="footer-wrapper" />
        </div>

    );
}