import React, {useEffect, useRef, useState} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import {convertFromHTML, EditorState, ContentState, convertToRaw, RawDraftContentState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import convertToHTML from 'draftjs-to-html';




import classnames from 'classnames';

export type Props = {
    readOnly?: boolean
    value: string,
    onChange: (state: RawDraftContentState) => void
} & DefaultProps;


const toolbarOptions = {
    options: ["inline", "link", "list", "remove"],
    inline: {
        options: ["bold", "italic", "underline", "strikethrough"],
    },
};

const getInitState = (value?: string) => {
    if (!value) {
        return EditorState.createEmpty();
    }

    const content =  ContentState.createFromBlockArray(convertFromHTML(value).contentBlocks);
    return EditorState.createWithContent(content);
}

export default function SimpleEditor({style, className, readOnly, value, onChange}: Props) {
    const [editorState, setEditorState] = useState(() => getInitState(value));
    const editorRef = useRef<Editor|null>(null);

    useEffect(() => {
        setEditorState(getInitState(value));
    }, [value]);

    const onStateChange = (newState:any) => {
        setEditorState(newState);
    }

    const onContentStateChane = (rawContent: RawDraftContentState) => {
        onChange(rawContent);
    }

    return (<div style={style} className={classnames('simple-editor', className)}>
        <Editor
            ref={editorRef}
        editorState={editorState}
        toolbarClassName="simple-editor-toolbar"
        wrapperClassName="simple-editor-wrapper"
        editorClassName="simple-editor-editor px-2"
        onEditorStateChange={onStateChange}
        toolbar={toolbarOptions}
        onContentStateChange={onContentStateChane}
        editorStyle={{position: 'relative', zIndex: 2, minHeight: '30vh'}}

    />

    </div>);
}
