import React from 'react';
import {Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import {Col, Container, Nav, Row} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import Icon from '../Icon/Icon';


export type Props = {
    addItem: LayoutNavItem,
    items: LayoutNavItem[],
} & DefaultProps;


export type LayoutNavItem = {
    to: string,
    text: string,
}


export default function LayoutNav({style, className, items, addItem}: Props) {
    const {pathname} = useLocation();

    return (
        <Row className={classnames("m-0 p-0", "application-nav", className)} style={style}>
            <Col xs={12} className="d-flex justify-content-between align-items-center">
                <Nav as="ul" variant="underline">
                    <Nav.Item as="li">
                        <Nav.Link as={Link} to={addItem.to} eventKey="nav-add" className="d-flex"  active={pathname.includes(addItem.to)}>
                            <Icon type="gg-add" style={{position: 'relative', top: '3px'}} />
                            <span style={{position: 'relative', top: '2px'}}>{addItem.text}</span>
                        </Nav.Link>
                    </Nav.Item>
                </Nav>
                <Nav as="ul" variant="underline">
                    {items.map(item =>  <Nav.Item key={item.to}  as="li">
                        <Nav.Link as={Link} to={item.to} eventKey="nav-card" active={pathname.includes(item.to)}>
                            {item.text}
                        </Nav.Link>
                    </Nav.Item>)}
                </Nav>
            </Col>
        </Row>
    );
}