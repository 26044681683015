import React, {MouseEvent, useCallback, useMemo, useState} from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import {Attachment, Doc as DocEntity} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import {lightOrDark} from '../../../../utils/common/lightOrDark';
import Icon from '../../Icon/Icon';
import PanelButton from '../CardPanelButton/CardPanelButton';
import CardPanel from '../CardPanel/CardPanel';
import {getDataUrlFromAttachment, getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import sanitizeExcerpt from '../../../../utils/common/sanitize';
import {useNavigate} from 'react-router-dom';
import {routeNotesDelete, routeNotesEdit} from '../../../../routes';
import configs from '../../../../configs';
import Title from '../ItemCard/Title/Title';
import CollectionMeta from '../ItemCard/CollectionMeta/CollectionMeta';
import {getFormattedDate} from '../../../../utils/common/datetime';

export type Props = {
    card: DocEntity,
    onClick?: (event: MouseEvent<HTMLElement>) => void
    panel?: boolean,
} & DefaultProps;

export const maxChars = (str: string, len: number = 30) => {
    let excerpt = str.substring(0, len)

    if (excerpt.length < str.length) {
        excerpt += '...';
    }

    return excerpt;
}


export const makeExcerpt = (str: string, len: number = 7) => {
    const excerpt = str.split(' ').slice(0, len);

    const lastWord = excerpt.pop();

    if (lastWord) {
        excerpt.push(lastWord.replaceAll(/[?!;:.,&]/g, ''));
    }

    let newStr = excerpt.join(' ');

    if (excerpt.length < str.length) {
        newStr += '...';
    }

    return newStr;
}

export default function NoteCard({card, onClick, className, style, panel = true}: Props) {
    const [showPanel, setShowPanel] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const tags = useMemo(() => {
        if (!card.tags) {
            return [];
        }

        return card.tags.filter(tag => !!tag);
    }, [card.tags])

    const bgColor = card.collection?.color || configs.cards.defaultColor;
    const color = 'text-white';
    const sanitizedExcerpt = useMemo(() => {
        return makeExcerpt(sanitizeExcerpt(card.content || ''));
    }, [card.content])


    const onEdit = useCallback( (card) => {
        navigate(routeNotesEdit(card.type, card._id));
    }, [card]);

    const onDelete = useCallback((card) => {
        navigate(routeNotesDelete(card._id));
    }, [card])

    return (
        <Card
            id={card._id}
            className={classnames('item-card position-relative pb-2 default-background border-0', color, className)}
            style={{cursor: 'pointer',...style}}
            onClick={onClick}
            onMouseEnter={() => setShowPanel(true)}
            onMouseLeave={() => setShowPanel(false)}
        >
            {panel && showPanel && <CardPanel>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    setCollapsed(!collapsed)
                }}>
                    <Icon type="gg-merge-horizontal" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onEdit(card);
                }}>
                    <Icon type="gg-pen" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onDelete(card);
                }}>
                    <Icon type="gg-trash" className={color}  />
                </PanelButton>
            </CardPanel> }
            <div className="d-flex align-items-center">
                {!collapsed && <div className="d-flex ps-3 pt-3 justify-content-center align-items-center" style={{width: '20%'}}>
                    {card._attachments ? <img
                        src={getDataUrlFromFirstAttachment(card._attachments)}
                        alt="Note Image"
                        className="me-3"
                    /> : <Icon type="ti-thought" className="me-3" style={{fontSize: '2.5rem'}} /> }
                </div>}
                <Card.Body className={classnames({"ps-0": !collapsed})}>
                    <Title text={card.name} className="m-0 p-0" />
                    {!collapsed && card.content && <Card.Text className="mb-1">{sanitizedExcerpt}</Card.Text>}
                    {!collapsed && tags && tags.length > 0 && <Card.Text>
                        {'#'+tags.map(tag => tag?.name || 'Unknown').join(' #')}
                    </Card.Text>}
                </Card.Body>
            </div>


            {!collapsed && <div className="item-card-footer d-flex flex-column">
                <div className="d-flex align-self-end" style={{width: '80%', position: 'relative', left: '1px'}} >
                    <CollectionMeta text={card.collection?.name} />
                    <span className="mx-2" dangerouslySetInnerHTML={{__html: '&centerdot;'}} />
                    <div className="d-flex">
                        {getFormattedDate(card.created_at)}
                    </div>
                    <div className="ms-4 float-end  rounded-circle position-absolute " style={{backgroundColor: bgColor, width: "20px", height: "20px", right: "0"}} />
                </div>
            </div>}
        </Card>
    );
}