import {Attachment} from '../../db/types';

export function faviconURL(pageUrl: string) {
    const url = new URL(chrome.runtime.getURL("/_favicon/"));
    url.searchParams.set("pageUrl", pageUrl);
    url.searchParams.set("size", "48");
    return url.toString();
}

export async function getFaviconAttachment(pageUrl: string): Promise<Attachment|undefined> {
    const response = await fetch(faviconURL(pageUrl));

    if (response.ok) {
        const blob = await response.blob();

        return new Promise((resolve) => {
            const fileReader = new FileReader();

            fileReader.onloadend = (result) => {
                resolve({
                    content_type: blob.type,
                    data: fileReader.result as string,
                })
            }

            fileReader.readAsDataURL(blob);
        });
    }
}