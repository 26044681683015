import {Entity} from '../../db/types';

export const userLocale =
    navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;


export const BACKGROUND_DOCUMENT_ID = 'default-background-document-id';

export const DEFAULT_COLLECTION_ID = '2023-08-10T00:00:00.000ZR001';
export const DEFAULT_NOTE_ID = '2023-08-10T00:00:00.000ZR002';

export const ACCOUNT_DATA_KEY = 'account_data';

export const availableEntities: Entity[] = [
    {
        _id: 'all',
        name: 'All'
    },
    {
        _id: 'goals',
        name: 'Goals',
    },
    {
        _id: 'notes',
        name: 'Notes'
    },

    {
        _id: 'tasks',
        name: 'Tasks'
    },

    {
        _id: 'events',
        name: 'Event'
    },

    {
        _id: 'bookmarks',
        name: 'Bookmarks'
    },
]