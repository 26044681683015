import {ListGroup} from 'react-bootstrap';
import React from 'react';
import hash from 'object-hash';
import {Message} from '../../../db/types';
import DefaultProps from '../../../types/DefaultProps';


export type Props = {
    errors: Message[]
} & DefaultProps;

export default function ErrorMessage({errors, style, className}: Props) {
    return (
        <ListGroup style={style} className={className}>
            {errors.map((error) => <ListGroup.Item variant="danger"  key={error.code || hash(error.message)}>{error.message.replaceAll(/,( )?/g, ', ')}</ListGroup.Item>)}
        </ListGroup>
    );
}