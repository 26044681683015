import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Doc, END_OF_DATA_POINTER, Event, TaskStatus, Taxonomy} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import ViewCardModal from '../../../../Common/ViewCardModal/ViewCardModal';
import CollectionSelect from '../../../../Common/CollectionSelect/CollectionSelect';
import useAppTitle from '../../../../../hooks/useAppTitle';
import EventCard from '../../../../Common/Cards/EventCard/EventCard';
import {getDayStartDate, getLocalDate} from '../../../../../utils/common/datetime';
import classnames from 'classnames';
import useListItemsWithSort, {Pointer} from '../../../../../hooks/useListItemsWithSort';
import ViewEvent from '../../../../Common/Event/ViewEvent/ViewEvent';
import ViewItemModal from '../../../../Common/Item/ViewItemModal/ViewItemModel';


const makeListEvents = (startingDate: string, routeContext: string = "all") => {

    return  async (options: {query: string, collection: string, sort: string, pointer: Pointer}) => {
        const response = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listEvents,
            data: {
                collection: options.collection,
                query: options.query,
                pointer: options.pointer?.value,
                limit: 100,
                sortBy: 'starting_at',
                sortDirection: options.sort,
                starting_at: startingDate,
                context: routeContext,
            }
        });

        if (response.error || !response.data) {
            throw response.error;
        }

        return response.data?.items ? {...response.data} : {items: []} as unknown as {items: any[], pointer: string|null};
    }
}


export default function ListEventsScreen() {

    const data = useLoaderData() as {collections: Taxonomy[], routeContext: string};

    const listEvents = useMemo(() => {
        return makeListEvents(new Date().toISOString().slice(0, 10), data.routeContext);
    }, []);


    const [pickedCard, setPickedCard] = useState<Doc|null>(null);
    const {items, onSortChange, setItems, reset, collection, query, onCollectionSelectChange, onSearchChange, onLoadMore, RenderedErrors, addError, clearErrors, hasErrors, pointer, setPointer, isLoadingMore, setIsLoadingMore} = useListItemsWithSort<Event>({listCallback: listEvents, defaultSort: 'asc'});




    const showLoadMore = pointer.value !== END_OF_DATA_POINTER && pointer.value;

    useAppTitle('Events');


    useEffect(() => {
        onLoadMore();
    }, [])


    const onCardClick = useCallback((card) => {
        setPickedCard(card);
    } , []);


    let groupDates: string[] = [];

    const render = (
        <Row className="m-0 application pb-2">
            <Col xs={12}>
                 <SearchBar
                     onClick={(query) => onSearchChange(query)}
                     onBlur={(query) => onSearchChange(query)}
                     className="mt-3 mb-1 default-background" query={query}
                 />
                 <CollectionSelect value={collection} className="mb-2 default-background" onChange={onCollectionSelectChange} collections={data.collections} />
                <div className="d-flex justify-content-end mb-4">
                    <Form.Check type="switch">
                        <Form.Check.Input
                            type="checkbox"
                            className="bg-secondary border-0"
                            onChange={(event) => {
                                onSortChange(event.target.checked ? 'desc' : 'asc');
                            }}
                        />
                        <Form.Check.Label className="text-white">View Past</Form.Check.Label>
                    </Form.Check>
                </div>

                {hasErrors && <RenderedErrors className="mb-2" />}
                {isLoadingMore && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}

                {pickedCard && <ViewItemModal show={!!pickedCard} item={pickedCard} onClose={() => setPickedCard(null)} />}
                {items.map((event, index) => {
                    const startingAt = getLocalDate(event.starting_at);
                    const render = (
                        <div key={event._id}>
                            {!groupDates.includes(startingAt) && <div className={classnames("h6 text-white", {"mt-5": index > 0})}>{startingAt}</div>}
                            <EventCard event={event} onClick={() => onCardClick(event)} className="mb-2" />
                        </div>
                    );

                    groupDates.push(startingAt);
                    return render;
                })}

                {!isLoadingMore && items.length < 1 && <div className="text-white mb-2">No events were found...</div>}
                {showLoadMore && <PrimaryButton spinnerVariant="light" className="w-100" variant="outline-primary" isProcessing={isLoadingMore} onClick={onLoadMore}>Load More</PrimaryButton>}
            </Col>
        </Row>
    );

    groupDates = [];

    return render;
}