import {Button, Spinner} from 'react-bootstrap';
import React, {MouseEventHandler, PropsWithChildren} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import './PrimaryButton.scss'

export type Props = {
    isProcessing?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement>
    type?: 'button' | 'submit' | 'reset',
    spinnerVariant?: 'dark' | 'light'
    variant?: 'primary' | 'secondary' | 'success' | 'warning' | 'danger' | 'info' | 'outline-primary' | 'outline-secondary' | 'outline-success' | 'outline-warning' | 'outline-danger' | 'outline-info'
} & DefaultProps

export default function PrimaryButton({children, isProcessing, onClick, className, style, variant = 'primary', type = 'submit', spinnerVariant = 'dark'}: PropsWithChildren<Props>) {
    return (
        <Button
            variant={variant}
            className={classnames("primary-button d-flex justify-content-center align-items-center", className)}
            style={style}
            type={type}
            disabled={isProcessing}
            onClick={onClick}
        >
            <span>{children}</span>
            {isProcessing && <Spinner variant={spinnerVariant} animation="border" size="sm" className="d-block ms-2" />}
        </Button>
    );
}