import React, {useCallback, useEffect} from 'react';
import {WorldClock} from '../../../../../db/types';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {routeMessages, routeWorldClocksList} from '../../../../../routes';
import WorldClockEdit from '../../../../Common/WorldClock/WorldClockEdit/WorldClockEdit';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function EditWorldClocksScreen() {
    const navigate = useNavigate();
    const {worldClock} = useLoaderData() as {worldClock: WorldClock};

    useAppTitle('World Clocks', worldClock ? 'Edit World Clock' : 'New World Clock');


    const onSubmit = useCallback(async (worldClock?: WorldClock) => {
        if (worldClock) {
            navigate(routeMessages('successful-world-clock-saving'))
        }
    }, []);

    const onCancel = useCallback(() => {
        navigate(routeWorldClocksList());
    }, [])


    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <WorldClockEdit worldClock={worldClock} onSubmit={onSubmit} onCancel={onCancel} className="w-100" style={{minHeight: "100vh"}} />
        </div>
    );
}