import {Environments, getEnv} from './env';

export default function getAppTitle(...sections) {
     let title = 'Workspace Dashboard';

    if (getEnv() === Environments.PWA) {
        title = '';
    }

     if (sections.length < 1) {
         return title;
     }

     return (title ? title + ' - ' : '') + sections.join(' - ');
}