import React, {useCallback, useEffect} from 'react';
import {Bookmark} from '../../../../../db/types';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {routeBookmarksList, routeMessages} from '../../../../../routes';
import BookmarkEdit from '../../../../Common/BookmarkEdit/BookmarkEdit';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function EditBookmarkScreen() {
    const navigate = useNavigate();
    const {bookmark} = useLoaderData() as {bookmark: Bookmark};

    useAppTitle('Bookmarks', bookmark ? 'Edit Bookmark' : 'New Bookmark');


    const onSubmit = useCallback(async (bookmark?: Bookmark) => {
        if (bookmark) {
            navigate(routeMessages('successful-bookmark-saving'))
        }
    }, []);

    const onCancel = useCallback(() => {
        navigate(routeBookmarksList());
    }, [])


    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <BookmarkEdit bookmark={bookmark} onSubmit={onSubmit} onCancel={onCancel} className="w-100" style={{minHeight: "100vh"}} />
        </div>
    );
}