import React, {MouseEvent, useCallback, useMemo, useState} from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import {Bookmark} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import {lightOrDark} from '../../../../utils/common/lightOrDark';
import Icon from '../../Icon/Icon';
import PanelButton from '../CardPanelButton/CardPanelButton';
import CardPanel from '../CardPanel/CardPanel';
import {getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import {useNavigate} from 'react-router-dom';
import {routeBookMarksDelete, routeBookmarksEdit} from '../../../../routes';
import configs from '../../../../configs';
import {shorten} from '../../../../utils/common/common';
import CollectionMeta from '../ItemCard/CollectionMeta/CollectionMeta';
import Title from '../ItemCard/Title/Title';
import {getFormattedDate} from '../../../../utils/common/datetime';

export type Props = {
    bookmark: Bookmark,
    onClick?: (event: MouseEvent<HTMLElement>) => void
    panel?: boolean,
} & DefaultProps;



export default function BookmarkCard({bookmark, onClick, className, style, panel = true}: Props) {
    const [showPanel, setShowPanel] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();

    const tags = useMemo(() => {
        if (!bookmark.tags) {
            return [];
        }

        return bookmark.tags.filter(tag => !!tag);
    }, [bookmark.tags])


    const bgColor = bookmark.collection?.color || configs.cards.defaultColor;
    const color = 'text-white';


    const onEdit = useCallback( (bookmark) => {
        navigate(routeBookmarksEdit(bookmark._id));
    }, []);

    const onDelete = useCallback((bookmark) => {
        navigate(routeBookMarksDelete(bookmark._id));
    }, [])

    return (
        <Card
            id={bookmark._id}
            className={classnames('item-card position-relative pb-2 border-0 default-background', color, className)}
            style={{cursor: 'pointer', ...style}}
            onClick={onClick}
            onMouseEnter={() => setShowPanel(true)}
            onMouseLeave={() => setShowPanel(false)}
        >
            {panel && showPanel && <CardPanel>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    setCollapsed(!collapsed)
                }}>
                    <Icon type="gg-merge-horizontal" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onEdit(bookmark);
                }}>
                    <Icon type="gg-pen" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onDelete(bookmark);
                }}>
                    <Icon type="gg-trash" className={color}  />
                </PanelButton>
            </CardPanel> }
            <div className="d-flex align-items-center">
                {!collapsed && <div className="d-flex ps-3 pt-3 justify-content-center align-items-center" style={{width: '20%'}}>
                    {bookmark._attachments &&  <img
                        src={getDataUrlFromFirstAttachment(bookmark._attachments)}
                        alt="Website Favicon"
                        className="me-3"
                        style={{width: '48px', height: '48px', objectFit: 'cover'}}
                    />}
                </div>}
                <Card.Body className={classnames({"ps-0": !collapsed})}>
                    <Title text={bookmark.name} className="m-0 p-0" />
                    {!collapsed && <Card.Text className="mb-1">{shorten(bookmark.url, 40)}</Card.Text>}
                    {!collapsed && tags && tags.length > 0 && <Card.Text>
                        {'#'+tags.map(tag => tag?.name || 'Unknown').join(' #')}
                    </Card.Text>}
                </Card.Body>
            </div>


            {!collapsed && <div className="item-card-footer d-flex flex-column">
                <div className="d-flex align-self-end" style={{width: '80%', position: 'relative', left: '1px'}} >
                    <CollectionMeta text={bookmark.collection?.name } />
                    <span className="mx-2" dangerouslySetInnerHTML={{__html: '&centerdot;'}} />
                    <div className="d-flex">
                        {getFormattedDate(bookmark._id)}
                    </div>
                    <div className="ms-4 float-end  rounded-circle position-absolute " style={{backgroundColor: bgColor, width: "20px", height: "20px", right: "5px"}} />
                </div>
            </div>}
        </Card>
    );
}