// @ts-ignore
export const isDev = process.env.NODE_ENV !== 'production';

export const log =  (...msg: any) => {
    if (!isDev) {
        return;
    }
    console.log(...msg);
}

export const error =  (...msg: any) => {
    if (!isDev) {
        return;
    }
    console.error(...msg);
}

export const dir = (msg: any) => {
    if (!isDev) {
        return;
    }

    console.dir(msg);
}


export const logRuntimeLastError = () => {
    if (isDev) {
        //return;
    }

    if (chrome.runtime.lastError) {
        console.dir(chrome.runtime.lastError.message);
    }
}
