import React, {PropsWithChildren} from 'react';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';

export default function CardPanel({children, className, style}: PropsWithChildren<DefaultProps>) {
    return (
        <div
            className={classnames('item-card-panel position-absolute d-flex justify-content-end align-items-start top-0 left-0 h-100 w-100 z-3', className)}
            style={{backgroundColor: 'rgba(255, 255, 255, .07)', ...style}}>
            {children}
        </div>
        )
}