import React from 'react';
import {Goal} from '../../../../db/types';
import {Alert} from 'react-bootstrap';

export type Props = {
    goal: Goal
}

export default function GoalHeader({goal}: Props) {
    return (
        <div>
            <h1 className="h4 text-white mt-2">Goal: {goal.name}</h1>
            <Alert variant="success">
                {`You've entered a goal context. That means you can see only tasks, events, notes and secondary goals related to the selected primary goal.`}
            </Alert>
        </div>
    );
}