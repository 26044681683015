import React from 'react';
import {Taxonomy} from '../../../../../db/types';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Doc as DocEntity} from '../../../../../db/types';
import {routeNotesList} from '../../../../../routes';
import useAppTitle from '../../../../../hooks/useAppTitle';
import ViewNote from '../../../../Common/Note/ViewNote/ViewNote';

export default function ViewNoteScreen() {
    const navigate = useNavigate();
    const {card, routeContext} = useLoaderData() as {card: DocEntity, collections: Taxonomy[], routeContext: string};

    useAppTitle('Notes', 'View Note');



    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            {card && <ViewNote note={card} onBack={()=> {
                navigate(routeNotesList(routeContext));
            }} />}
        </div>
    );
}