import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Response, Task} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeGoalsList} from '../../../../../routes';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import TaskCard from '../../../../Common/Cards/TaskCard/TaskCard';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteGoalScreen() {
    const response = useLoaderData() as Response & {routeContext: string};
    const navigate = useNavigate();

    useAppTitle('Goals', 'Delete Goal');

    const onDelete = useCallback(async () => {
       const r = await sendRequest({
           _id: getTimestampId(),
           generic: 'request',
           type: Requests.removeGoal,
           data: {_id: response.data?._id}
       });

       return {response: r, route: routeGoalsList(response.routeContext)}
    }, [response, response.routeContext]);

    const onCancel = useCallback(() => {
        navigate(routeGoalsList(response.routeContext));
    }, []);

    return (
        <DeleteScreen
            title="Delete Goal"
            onDelete={onDelete}
            onCancel={onCancel}
            text="Are you sure you want to delete this goal?"
        >
            {response.data && <TaskCard task={response.data as Task} panel={false} />}
        </DeleteScreen>
    );
}