import React, {useEffect} from 'react';
import {Card} from 'react-bootstrap';
import {useLoaderData, useNavigate} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import {
    routeBookmarksList, routeEntityTaxonomiesEdit, routeEntityTaxonomiesList, routeEventsList, routeGoalsList,
    routeNotesList,
    routeTasksList,
    routeTaxonomiesList,
    routeWorldClocksList
} from '../../../../../routes';
import useAppTitle from '../../../../../hooks/useAppTitle';

const messages: Record<string, {type: string, data: string, navigate: (context:string, entity?: string) => string}> = {
    'success': {type: 'success', data: 'Congrats! The operation was successful...', navigate: (context: string) => routeNotesList(context)},
    'successful-note-deletion': {type: 'success', data: 'Success! The note was deleted...', navigate: (context: string) => routeNotesList(context)},
    'successful-note-saving': {type: 'success', data: 'Congrats! The note was saved...', navigate: (context: string) => routeNotesList(context)},
    'successful-task-saving': {type: 'success', data: 'Congrats! The task was saved...', navigate: (context: string) => routeTasksList(context)},
    'successful-goal-saving': {type: 'success', data: 'Congrats! The goal was saved...', navigate: (context: string) => routeGoalsList(context)},
    'successful-event-saving': {type: 'success', data: 'Congrats! The task was saved...', navigate: (context: string) => routeEventsList(context)},
    'successful-collection-saving': {type: 'success', data: 'Congrats! The collection was saved...', navigate: (context: string, entity?: string) => routeEntityTaxonomiesList(`/${entity}`, 'collection', context)},
    'successful-tag-saving': {type: 'success', data: 'Congrats! The tag was saved...', navigate: (context: string, entity?: string) => routeEntityTaxonomiesList(`/${entity}`, 'tag', context)},
    'successful-collection-deletion': {type: 'success', data: 'Congrats! The collection was deleted...', navigate: (context: string, entity?: string) => routeEntityTaxonomiesList(`/${entity}`, 'collection', context)},
    'successful-tag-deletion': {type: 'success', data: 'Congrats! The tag was deleted...', navigate: (context: string, entity?: string) => routeEntityTaxonomiesList(`/${entity}`, 'tag', context)},
    'successful-bookmark-deletion': {type: 'success', data: 'Success! The bookmark was deleted...', navigate: (context: string) => routeBookmarksList(context)},
    'successful-bookmark-saving': {type: 'success', data: 'Congrats! The bookmark was saved...', navigate: (context: string) => routeBookmarksList(context)},
    'successful-world-clock-saving': {type: 'success', data: 'Congrats! The world clock was saved...', navigate: (context: string) => routeWorldClocksList(context)},
    'successful-world-clock-deletion': {type: 'success', data: 'Success! The world clock was deleted...', navigate: (context: string) => routeWorldClocksList(context)},
}

export default function MessageScreen() {
    const params = useLoaderData() as Record<string, string>;
    const message = messages[params?.messageId || 'success']
    const navigate = useNavigate();

    useAppTitle('Message');

    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <Card className="bg-transparent border-0 w-100 py-3" style={{minHeight: "100vh"}}>
                <Card.Body className="p-0">
                    <div className="default-background p-3">
                        <div className="text-white">{message.data}</div>
                        <PrimaryButton className="mt-3" onClick={() => navigate(message.navigate(params.context, params.entity))}>
                            Close
                        </PrimaryButton>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}