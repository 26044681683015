import React, {ForwardedRef} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import './Icon.scss';

export type IconType = 'gg-search'|'gg-close'|'gg-pen'|'gg-trash' | 'gg-merge-horizontal' | 'gg-box'|'gg-add'|'gg-folder'|string;

export type Props = {
    type: IconType,
    onClick?: () => void,
} & DefaultProps;

const Icon = React.forwardRef((props: Props & any, ref: ForwardedRef<any>) => {
    return <span
        ref={ref}
        style={props.style}
        className={classnames('icon', props.className, props.type)}
        onClick={props.onClick}
        onMouseOut={props.onMouseOut}
        onMouseOver={props.onMouseOver}
    />
});

Icon.displayName = 'Icon';

export default Icon;