import React, {PropsWithChildren} from 'react';
import {Card} from 'react-bootstrap'
import DefaultProps from '../../../../../types/DefaultProps';
import {maxChars} from '../../NoteCard/NoteCard';

export type Props = {
    maxLength?: number
    text: string
} & DefaultProps

export default function Title({className, style, text, maxLength = 28}: Props) {
    return (<Card.Title className={className} style={style}>
        {maxChars(text, 28)}
    </Card.Title>)
}