import React, {ChangeEvent, useMemo} from 'react';
import {Form} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import {Taxonomy} from '../../../db/types';
import {filterTaxonomiesByEntities} from '../../../utils/common/taxonomies';

export type Props = {
    value: string,
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void
    collections: Taxonomy[],
} & DefaultProps;

export default function CollectionSelect({value, onChange, collections, className, style}: Props) {

    return (
        <div className={className} style={style}>
            <Form.Select onChange={onChange} value={value} className="py-2">
                <option value="" className="text-black">All</option>
                {collections.map(collection => <option key={collection._id} value={collection._id} className="text-black">
                    {collection.name}
                </option>)}
            </Form.Select>
        </div>
    );
}