import React from 'react';
import DefaultProps from '../../../../types/DefaultProps';
import {DocumentType} from '../../../../db/types';
import ViewTask from '../../Task/ViewTask/ViewTask';
import ViewEvent from '../../Event/ViewEvent/ViewEvent';
import ViewNote from '../../Note/ViewNote/ViewNote';
import ViewGoal from '../../Goal/ViewGoal/ViewGoal';

export type Props = {
    item: any,
    onBack: () => void,
    onChange?: () => void,
} & DefaultProps;

export default function ViewItem({item, onBack, className, style, onChange}: Props) {
    switch (item.doc_type) {
        case DocumentType.TASK:
            return <ViewTask task={item} onBack={onBack} className={className} style={style} onChange={onChange} />;
        case DocumentType.EVENT:
            return <ViewEvent event={item} onBack={onBack} className={className} style={style} onChange={onChange} />;
        case DocumentType.GOAL:
            return <ViewGoal goal={item} onBack={onBack} className={className} style={style} onChange={onChange} />;
        default:
            return <ViewNote note={item} onBack={onBack} className={className} style={style} onChange={onChange} />;
    }
}