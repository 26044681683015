import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Bookmark, Response} from '../../../../../db/types';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeBookmarksList} from '../../../../../routes';
import BookmarkCard from '../../../../Common/Cards/BookmarkCard/BookmarkCard';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteBookmarkScreen() {
    const response: Response = useLoaderData() as Response;
    const navigate = useNavigate();

    useAppTitle('Bookmarks', 'Delete Bookmark');

    const onDelete = useCallback(async () => {;
        const r = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.removeBookmark,
            data: {_id: response.data?._id}
        });

        return {response: r, route: routeBookmarksList()}
    }, [response]);

    const onCancel =  useCallback(async () => {;
        navigate(routeBookmarksList());
    }, [response]);

    return (
        <DeleteScreen
            title="Delete Bookmark"
            onDelete={onDelete}
            onCancel={onCancel}
            text="Are you sure you want to delete this bookmark?"
        >
            {response.data && <BookmarkCard bookmark={response.data as Bookmark} panel={false} />}
        </DeleteScreen>
    );
}