import {Requests} from '../../api/requests';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {sendRequest} from '../../api/frontend/api';

export default async function deleteTaxonomiesLoader({params}) {
    if (!params._id) {
        throw new Error('No taxonomy id');
    }

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getTaxonomy,
        data: {_id: params._id}
    });

    return {...response, routeContext: params.context} || {data: null, routeContext: params.context};
}