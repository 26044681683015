import React, {useCallback, useMemo} from 'react';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import {Doc} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';
import {sanitizeContent} from '../../../../utils/common/sanitize';

export type Props = {
    note: Doc,
    onBack: () => void
    onChange?: () => void,
} & DefaultProps;

export default function ViewNote({note, onBack, className, style}: Props) {
    const sanitizedDescription = useMemo(() => {
        if (note?.content) {
            return sanitizeContent(note.content);
        }

        return '';
    }, [note?.content]);


    return (
        <Card className={classnames("bg-dark w-100 p-3 border-0", className)} style={style}>
            <Card.Body>
                <Form>
                    <div className="d-flex flex-column justify-content-start align-items-center mb-3">
                        {note._attachments && <div className="d-flex align-items-center justify-content-center me-4 dropzone">
                            <img src={getDataUrlFromFirstAttachment(note._attachments)} style={{objectFit: 'cover', width: '100%', height: '100%'}} />
                        </div>}

                        <div className="w-100 mt-4">
                            <FormGroup className="mb-2">
                                <FormLabel htmlFor="name" className="text-white">Name</FormLabel>
                                <FormControl type="text" name="name" className="default-background" defaultValue={note.name} readOnly={true}  />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="collection" className="text-white">Collections</FormLabel>
                                <Form.Select required={true} name="collection" className="default-background" defaultValue={note.collection?._id}>
                                    <option>
                                        {note.collection?.name}
                                    </option>
                                </Form.Select>
                            </FormGroup>

                        </div>
                    </div>

                    <FormGroup className="d-flex justify-content-between align-items-center my-2">
                        <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                        <FormControl
                            type="color"
                            name="color"
                            disabled={true}
                            value={note.collection?.color}
                            onChange={() => {}}
                        />
                    </FormGroup>

                    {!!sanitizedDescription && <FormGroup>
                        <FormLabel htmlFor="description" className="text-white">Content</FormLabel>
                        <div className="bg-black p-2 default-background" dangerouslySetInnerHTML={{__html: sanitizedDescription}} />
                    </FormGroup>}

                    <div style={{backgroundColor: '333333'}} className="mt-3">
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
}