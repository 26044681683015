import {Outlet, useLocation } from 'react-router-dom';
import React, {useEffect, useMemo, useState} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {
    routeWorldClocksEdit, routeWorldClocksList,
} from '../../../routes';
import LayoutNav, {LayoutNavItem} from '../../Common/LayoutNav/LayoutNav';
import ApplicationLayout from './ApplicationLayout';

export type Props = DefaultProps;

export const navItems: LayoutNavItem[] = [
    {
        to: routeWorldClocksList(),
        text: 'World Clocks'
    },
];

export function getContentAwareNavLink(url?: string): LayoutNavItem {
    return {text: 'Add World Clock', to: routeWorldClocksEdit()};
}

export default function WorldClocksLayout({style, className}: Props) {
    const {pathname} = useLocation();
    const [addItem, setAddItem] = useState(getContentAwareNavLink(pathname));

   useEffect(() => {
       setAddItem(getContentAwareNavLink(pathname));
   }, [pathname]);

    return (
        <ApplicationLayout className={classnames(className)} style={{...style}}>
            <LayoutNav addItem={addItem} items={navItems} />
            <Outlet />
        </ApplicationLayout>)
}