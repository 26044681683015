import {Taxonomy, TaxonomyType} from '../db/types';
import {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {Requests} from '../api/requests';
import {sendRequest} from '../api/frontend/api';
import {getTimestampId} from '../utils/common/getTimestampId';
import {useResponseErrors} from './useResponseErrors';
import {filterTaxonomiesByEntities} from '../utils/common/taxonomies';

const useTaxonomyFeature = (loadedTaxonomies: Taxonomy[], editorType: TaxonomyType, entities: string[] = ['all'], context: string = 'all') => {
    const [stateTaxonomies, setStateTaxonomies] = useState<Taxonomy[]>([]);
    const [query, setQuery] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const {errors, hasErrors, clearErrors, RenderedErrors, addError} = useResponseErrors();

    useEffect(() => {
        const filtered = filterTaxonomiesByEntities(loadedTaxonomies, entities)
        setStateTaxonomies([...filtered]);
    }, [loadedTaxonomies?.length]);


    const onSearchChange = async (query: string) => {
        clearErrors();
        if (query && query?.length < 3) {
            setQuery(query);
            return;
        }

        const {data, error}= await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listTaxonomies,
            data: {
                query: query,
                type: editorType,
                context: context,
            }
        });

        if (error) {
            addError(error);
            return;
        }

        if (data?.taxonomies) {
            const filtered = filterTaxonomiesByEntities(data.taxonomies, entities)
            setStateTaxonomies([...filtered]);
        } else {
            setStateTaxonomies([]);
        }

        setQuery(query);
    }


    const result = useMemo(() => {
        return {taxonomies: stateTaxonomies, isLoading, setIsLoading, query, setQuery, onSearchChange, addError, hasErrors, errors, RenderedErrors};
    }, [isLoading, query, stateTaxonomies, errors]);

    return result;
}

export default useTaxonomyFeature;