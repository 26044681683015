import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Response, WorldClock} from '../../../../../db/types';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeWorldClocksList} from '../../../../../routes';
import WorldClockCard from '../../../../Common/Cards/WorldClockCard/WorldClockCard';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteWorldClocksScreen() {
    const response: Response = useLoaderData() as Response;
    const navigate = useNavigate();

    useAppTitle('World Clocks', 'Delete World Clock');

    const onDelete = useCallback(async () => {;
        const r = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.removeWorldClock,
            data: {_id: response.data?._id}
        });

        return {response: r, route: routeWorldClocksList()}
    }, [response]);

    const onCancel =  useCallback(async () => {;
        navigate(routeWorldClocksList());
    }, [response]);

    return (
        <DeleteScreen
            title="Delete World Clock"
            onDelete={onDelete}
            onCancel={onCancel}
            text="Are you sure you want to delete this world clock?"
        >
            {response.data && <WorldClockCard worldClock={response.data as WorldClock} panel={false} />}
        </DeleteScreen>
    );
}