import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';

export default async function editEventsLoader({params}) {

    if (!params._id) {
        return {event: null, routeContext: params.context};
    }


    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getEvent,
        data: {_id: params._id}
    });

    return {event: response.data || null, routeContext: params.context, error: response.error};
}