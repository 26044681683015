import React, {useState} from 'react'
import Icon from '../../Common/Icon/Icon';
import {
    routeEventsList, routeGoalsList, routeGoalsOverview,
    routeNotesList,
    routeTasksList,
    routeWorldClocksList
} from '../../../routes';
import DefaultProps from '../../../types/DefaultProps';
import {useLocation, useMatch, useNavigate} from 'react-router-dom';

import './Menu.scss'
import AuthModal from '../../Common/AuthModal/AuthModal';
import {Button} from 'react-bootstrap';

export const isActiveRoute = (segment, pathname) => pathname.includes(segment);

export const makeRouteMatch = (pathname: string) => pathname.split('/').map((segment, index) => segment != '' ?  `:param${index}` : ``).join('/');

export type Props = {
    onClick?: (route: string) => void,
} & DefaultProps

export default function Menu({className, style, onClick}: Props) {
    const navigate = useNavigate()
    const [showAuthModal, setShowAuthModal] = useState(false);

    const {pathname} = useLocation();
    const match = useMatch(makeRouteMatch(pathname));
    const context = match?.params?.param2 || 'all';
    const isAllContext = context === 'all';

    return (
        <div className="mobile-menu-wrapper">
            <div className="mobile-menu">

                {!isAllContext && <Button
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        onClick && onClick(routeGoalsList('all'));
                        navigate(routeGoalsList('all'));
                    }}
                >
                    <Icon
                        type="ti-arrow-circle-left"
                    />
                    Back
                </Button>}

                {!isAllContext && <Button
                    active={isActiveRoute(`/goals/${context}/overview`, pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        onClick && onClick(routeGoalsOverview(context));
                        navigate(routeGoalsOverview(context));
                    }}
                >
                    <Icon
                        type="ti-pulse"
                    />
                    Overview
                </Button>}


                <Button variant="outline-primary"
                        active={isActiveRoute('/goals/', pathname) && !isActiveRoute(`/goals/${context}/overview`, pathname)}
                        className="border-0"
                        onClick={() =>  {
                            onClick && onClick(routeGoalsList(context));
                            navigate(routeGoalsList(context));
                        }}
                >
                    <Icon
                        type="ti-star"
                    />

                    Goals
                </Button>


                <Button
                    active={isActiveRoute('/tasks/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        onClick && onClick(routeTasksList(context));
                        navigate(routeTasksList(context))
                    }}
                >
                    <Icon
                        type="ti-check-box"
                    />

                    Tasks
                </Button>

                <Button
                    active={isActiveRoute('/events/', pathname)}
                    variant="outline-primary"
                    className="border-0 justify-content-center d-flex flex-column align-items-center"
                    onClick={() => {
                        onClick && onClick(routeEventsList(context));
                        navigate(routeEventsList(context))
                    }}
                >
                    <Icon
                        type="gg-calendar-today"
                    />

                    Events
                </Button>

                <Button
                    active={isActiveRoute('/notes/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        onClick && onClick(routeNotesList(context));
                        navigate(routeNotesList(context))
                    }}
                >
                    <Icon
                        type="ti-agenda"
                    />

                    Notes
                </Button>


                {isAllContext && <Button
                    active={isActiveRoute('/world-clocks/', pathname)}
                    variant="outline-primary"
                    className="border-0"
                    onClick={() => {
                        onClick && onClick(routeWorldClocksList());
                        navigate(routeWorldClocksList())}
                    }

                >
                    <Icon
                        type="ti-time"
                    />

                    Clocks
                </Button>}

                <Button variant="outline-primary" className="border-0" onClick={() => setShowAuthModal(true)}>
                    <Icon
                        type="ti-cloud"
                    />

                    Cloud
                </Button>

            </div>
            {showAuthModal && <AuthModal show={showAuthModal} onClose={() => setShowAuthModal(false)} />}
        </div>
    )
}