import React from 'react';
import DefaultProps from '../../../../types/DefaultProps';
import PickModal from '../../PickModal/PickModal';
import ViewItem from '../ViewItem/ViewItem';

export type Props = {
    show?: boolean
    onClose?: () => void
    onChange?: () => void,
    item: any
} & DefaultProps;

export default function ViewItemModal({show, onClose, item, className, style, onChange}: Props) {
    return (
        <PickModal show={show} onClose={onClose} className={className} style={style} title={`View ${item.doc_type[0].toUpperCase() + item.doc_type.slice(1)}`}>
            <ViewItem item={item} onBack={() => onClose && onClose()} onChange={onChange} />
        </PickModal>
    );
}


