import React, {useState} from 'react';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import Icon from '../../Icon/Icon';
import TaxonomyModal from '../../TaxonomyModal/TaxonomyModal';
import DefaultProps from '../../../../types/DefaultProps';
import {Taxonomy} from '../../../../db/types';

export type Props = {
    tags: Taxonomy[],
    setTags: (tags: Taxonomy[]) => void,
    label?: string,
} & DefaultProps;

export default function TagsField({tags, setTags, className, style, label = 'Tags'}: Props) {
    const [openTags, setOpenTags] = useState(false);

    return (
        <FormGroup className={className} style={style}>
            <FormLabel htmlFor="tags" className="text-white">{label}</FormLabel>
            <div className="d-flex align-items-center mb-2">
                <FormControl type="text" className="default-background" name="tags" value={''} onChange={() => {}} placeholder="Click to find tags..." autoComplete="off" onClick={() => setOpenTags(true)} />
                <Button variant="light" className="default-background border-0" style={{width: '48px', height: '36px'}} onClick={() => setOpenTags(true)}>
                    <Icon type="gg-folder" className="" />
                </Button>
            </div>

            <TaxonomyModal
                editorType="tag" show={openTags}
                onPick={(t) => {
                    setOpenTags(false);

                    if (tags.map(tag => tag._id).includes(t._id)) {
                        return;
                    }

                    setTags([t, ...tags]);
                }}

                onCancel={() => setOpenTags(false)}
            />

            {tags.map(tag => <div key={tag._id} className="d-flex align-items-center mb-1 mt-2">
                <FormControl type="text" name="tags" value={`#${tag.name}`} onChange={() => {}}  />
                <Button variant="dark" className="bg-black" style={{width: '48px', height: '36px'}} onClick={() => setTags([...(tags.filter(t => t._id !== tag._id))])}>
                    <Icon type="gg-close" />
                </Button>
            </div>)}
        </FormGroup>
    );
}