import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Doc, END_OF_DATA_POINTER, Taxonomy} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import NoteCard from '../../../../Common/Cards/NoteCard/NoteCard';
import {Col, Row, Spinner} from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import useListItems, {Pointer} from '../../../../../hooks/useListItems';
import CollectionSelect from '../../../../Common/CollectionSelect/CollectionSelect';
import useAppTitle from '../../../../../hooks/useAppTitle';
import ViewItemModal from '../../../../Common/Item/ViewItemModal/ViewItemModel';

export const makeListNotes = (context: string) => {
    return async (options: {query?: string, collection?: string, pointer?: Pointer}) => {
        const response = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listNotes,
            data: {
                collection: options.collection,
                query: options.query,
                pointer: options.pointer?.value,
                limit: options.pointer?.limit,
                context: context
            }
        });

        if (response.error || !response.data) {
            throw response.error;
        }

        return response.data?.items ? {...response.data} : {items: []} as unknown as {items: any[], pointer: string|null};
    }
}

export default function ListNotesScreen() {

    const data = useLoaderData() as {collections: Taxonomy[], routeContext: string};

    const listNotes = useMemo(() => {
        return makeListNotes(data.routeContext)
    }, [data]);


    const [pickedCard, setPickedCard] = useState<Doc|null>(null);
    const {items, setItems, collection, query, onCollectionSelectChange, onSearchChange, onLoadMore, RenderedErrors, addError, clearErrors, hasErrors, pointer, setPointer, isLoadingMore, setIsLoadingMore} = useListItems<Doc>({listCallback: listNotes});


    const showLoadMore = pointer.value !== END_OF_DATA_POINTER && pointer.value;

    useAppTitle('Notes');

    useEffect(() => {
        onLoadMore();
    }, [])


    const onCardClick = useCallback((card) => {
        setPickedCard(card);
    } , []);



    return (
        <Row className="application pb-2 m-0 p-0">
            <Col xs={12}>
                <SearchBar
                    onClick={(query) => onSearchChange(query)}
                    onBlur={(query) => onSearchChange(query)}
                    className="mt-3 mb-1 default-background" query={query}
                />
                 <CollectionSelect value={collection} className="mb-2 default-background" onChange={onCollectionSelectChange} collections={data.collections} />,

                {hasErrors && <RenderedErrors className="mb-2" />}
                {isLoadingMore && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}

                {pickedCard && <ViewItemModal show={!!pickedCard} item={pickedCard} onClose={() => setPickedCard(null)} />}
                {items.map(card => <NoteCard key={card._id} card={card} onClick={() => onCardClick(card)} className="mb-2" />)}
                {!isLoadingMore && items.length < 1 && <div className="text-white mb-2">No notes were found...</div>}
                {showLoadMore && <PrimaryButton spinnerVariant="light" className="w-100" variant="outline-primary" isProcessing={isLoadingMore} onClick={onLoadMore}>Load More</PrimaryButton>}
            </Col>
        </Row>
    );
}