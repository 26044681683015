import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Button, Card, Form, FormGroup, FormLabel, FormSelect} from 'react-bootstrap';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import {WorldClock} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';
import {Requests} from '../../../../api/requests';
import {sendRequest} from '../../../../api/frontend/api';
import {getTimestampId} from '../../../../utils/common/getTimestampId';
import {useResponseErrors} from '../../../../hooks/useResponseErrors';
import {timezones} from '../../../../utils/common/timezones';
import {getModifiedAt} from '../../../../utils/common/datetime';

export type EditData = {
    _id?: string,
    timezone: string,
    modified_at?: string
}


export type Props = {
    worldClock?: WorldClock,
    onSubmit?: (worldClock: WorldClock) => void,
    onCancel?: () => void,
} & DefaultProps;

export default function WorldClockEdit({worldClock, onCancel, onSubmit, className, style}: Props) {
    const [data, setData] = useState<EditData>({timezone: ''});
    const [isSaving, setIsSaving] = useState(false);
    const {addError, RenderedErrors, clearErrors} = useResponseErrors();

    useEffect(() => {
        if (!worldClock) {
            return;
        }

        const newData = {...worldClock} as EditData;


        setData(newData);


    }, [worldClock])

    const onFormSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        clearErrors();
        event.preventDefault();
        setIsSaving(false)

        const modifiedAt = getModifiedAt(data?._id);

        const newClock: WorldClock = {
            _id: data._id || getTimestampId(),
            modified_at: modifiedAt,
            timezone: data.timezone,
        }

        const response = await sendRequest({
            _id: getTimestampId(),
            type: Requests.upsertWorldClock,
            generic: 'request',
            data: newClock,
        });

        setIsSaving(false);

        if (response?.data?.status === 'success') {
            setData({timezone: ''});
        }

        if (response?.error) {
            addError(response.error);
            return;
        }

        onSubmit && onSubmit(newClock);

    }, [data]);

    const onFormCancel = useCallback(() => {
        if (isSaving) {
            return;
        }

        onCancel && onCancel();
    }, [])



    return (
        <Card className={classnames("bg-transparent border-0", className)} style={style}>
            <Card.Body>
                <Form onSubmit={onFormSubmit}>
                    <div className="d-flex justify-content-start align-items-center mb-3">


                        <div className="w-100">
                            <FormGroup className="mb-2">
                                <FormLabel htmlFor="clock_timezone" className="text-white">Timezone</FormLabel>
                                <FormSelect value={data.timezone} className="default-background" required={true} onChange={(event) => setData({...data, timezone: event.target.value})}>
                                    <option value="" className="text-black">Choose...</option>
                                    {timezones.map(zone => <option className="text-black">{zone}</option>)}
                                </FormSelect>
                            </FormGroup>
                        </div>
                    </div>

                    <div style={{backgroundColor: '333333'}}>
                        <PrimaryButton className="w-100 mt-3" isProcessing={isSaving} type="submit">
                            Save
                        </PrimaryButton>
                        <Button variant="outline-primary text-white" className="w-100 mt-2" onClick={onFormCancel} disabled={isSaving}>Cancel</Button>
                    </div>

                    <RenderedErrors className="mt-2" />
                </Form>
            </Card.Body>
        </Card>
    );
}