import React, {useState} from 'react';
import {Button, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import Icon from '../../Icon/Icon';
import TaxonomyModal from '../../TaxonomyModal/TaxonomyModal';
import DefaultProps from '../../../../types/DefaultProps';
import {Taxonomy} from '../../../../db/types';

export type Props = {
    value?: Taxonomy,
    onChange: (val: Taxonomy) => void,
    entities?:  string[],
    required?: boolean,
} & DefaultProps;

export default function CollectionField({value, onChange, className, style, required = true, entities = ['all']}: Props) {
    const [openCollections, setOpenCollections] = useState(false);

    return (
        <FormGroup className={className} style={style}>
        <FormLabel htmlFor="collection" className="text-white">Collections</FormLabel>
        <div className="d-flex align-items-center">
            <FormControl type="text" className="default-background" name="collection" value={value?.name || ''} onChange={() => {}} placeholder="Click to find collection..." required={required} onClick={() => setOpenCollections(true)} />
            <Button variant="light" className="default-background border-0"  style={{width: '48px', height: '36px'}} onClick={() => setOpenCollections(true)}>
                <Icon type="gg-folder" />
            </Button>
        </div>


        <TaxonomyModal
            show={openCollections}
            editorType="collection"
            onPick={(t) => {
                onChange({...t});
                setOpenCollections(false);
            }}
            entities={entities}
            onCancel={() => setOpenCollections(false)}
        />
    </FormGroup>);
}