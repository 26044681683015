import React, {ChangeEvent} from 'react';
import {FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import configs from '../../../../configs';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';

export type Props = {
    value?: string,
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void,
    label?: string,
    readOnly?: boolean,
} & DefaultProps;

export default function ColorField({value, onChange, className, style, readOnly, label = 'Color'}: Props) {
    return (
        <FormGroup className={classnames("d-flex justify-content-between align-items-center", className)} style={style}>
            <FormLabel htmlFor="color" className="text-white">{label}</FormLabel>
            <FormControl
                type="color"
                name="color"
                readOnly={readOnly}
                value={value}
                onChange={onChange}
            />
        </FormGroup>
    );
}