import React, {useCallback, useRef, useState} from 'react';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {Button, Form} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import PasswordStrengthBar from 'react-password-strength-bar';
import configs from '../../../configs';
import {Requests} from '../../../api/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {AccountData} from '../../../db/types';

export type Props = {
    onSuccess: (data: Data) => void,
    onSignIn: () => void
}

export type Data = {
    email: string
    password: string
    license_key: string
    message?: string
}

export default function CreateAccount({onSuccess, onSignIn}: Props) {
    const {addError, RenderedErrors, clearErrors, hasErrors, errors} = useResponseErrors();
    const [passwords, setPasswords] = useState({password: '', confirm: ''})
    const [email, setEmail] = useState("")
    const [licenseKey, setLicenseKey] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const ref = useRef<HTMLFormElement|null>(null)

    const onNext = useCallback(() => {
        clearErrors()

        if (ref.current) {
            if (!ref.current?.reportValidity()) {
                return
            }
        }

        if (!email) {
            addError({message: "Please enter a valid email.", type: 'warning', stack: ""});
            return;
        }

        if (!licenseKey) {
            addError({message: "Please enter a valid license key.", type: 'warning', stack: ""});
            return;
        }

        if (!passwords.password) {
            addError({message: "Please enter a complex password.", type: 'warning', stack: ""});
            return;
        }

        if (passwords.password.length < 9) {
            addError({message: "Your password is too short. It must be at least 9 symbols.", type: 'warning', stack: ""});
            return;
        }

        if (passwords.password.match(/^[a-z0-9]+$/gi)) {
            addError({message: "Your password doesn't include a special symbol like one of these !@#$%^&*_+", type: 'warning', stack: ""});
            return;
        }

        if (passwords.password !== passwords.confirm) {
            addError({message: "The passwords don't match match.", type: 'warning', stack: ""});
            return;
        }

        const data = {
            email: email,
            password: passwords.password,
            license_key: licenseKey,
            message: "Your account was created. Please sign in..."
        }

        setIsProcessing(true)
        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.createAccount,
            data: {...data},
        }).then(response => {
            setIsProcessing(false)
            if (response.error) {
                addError(response.error);
                return;
            }

            onSuccess(data);

        }).catch(error => {
            setIsProcessing(false)
            console.error(error)
        });
    }, [ref, licenseKey, email, clearErrors, passwords, errors]);

    return (<>
        {!hasErrors && <p className="text-white">
            You can backup all your data in the cloud and unlock syncing with up to 5 devices. <br /><br />
        </p>}

        <RenderedErrors className="mb-3" />

        <Form ref={ref}>
            <Form.Group>
                <Form.Label className="text-white">Email</Form.Label>
                <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} required={true} placeholder="Enter an email" />
            </Form.Group>
            <Form.Group>
                <Form.Label className="text-white">License Key</Form.Label>
                <Form.Control type="text"  value={licenseKey} onChange={(event) => setLicenseKey(event.target.value)} required={true}  placeholder="Enter a license key" />
            </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="password" className="text-white">Password</Form.Label>
                <Form.Control type="password" name="password" value={passwords.password} onChange={(event) => setPasswords({...passwords, password: event.target.value})} placeholder="Enter a password" required={true} />
                <Form.Label htmlFor="confirm" className="text-white mt-3">Confirm Password</Form.Label>
                <Form.Control type="password" name="confirm" value={passwords.confirm} onChange={(event) => setPasswords({...passwords, confirm: event.target.value})} placeholder="Confirm your password" required={true} />
                <PasswordStrengthBar password={passwords.password} className="mt-2" />
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
               <div className="d-flex">
                   <PrimaryButton type="button" variant="secondary" isProcessing={isProcessing} onClick={() => window.open(configs.productPageAddress, "_blank")}>Get License Key</PrimaryButton>
                   <Button variant="outline-light" size="sm" className="d-inline-block ms-2" disabled={isProcessing} onClick={() => onSignIn()}>Sign In</Button>
               </div>
                <PrimaryButton type="button" onClick={onNext} isProcessing={isProcessing}>Next</PrimaryButton>
            </div>
        </Form>
    </>);

}