export enum Requests {
    checkAccountData = 'check_account_data',
    saveAccountData = 'save_account_data',
    getAccountData = 'get_account_data',
    openCalendar = 'open_calendar',
    listCalendarItems = 'list_calendar_items',
    insertGoal = 'insert_goal',
    upsertGoal = 'upsert_goal',
    removeGoal = 'remove_goal',
    listGoals = 'list_goals',
    getGoal = 'get_goal',
    openGoals = 'open_goals',
    insertTask = 'insert_task',
    upsertTask = 'upsert_task',
    removeTask = 'remove_task',
    listTasks = 'list_tasks',
    getTask = 'get_task',
    openTasks = 'open_tasks',
    insertEvent = 'insert_event',
    upsertEvent = 'upsert_event',
    removeEvent = 'remove_event',
    listEvents = 'list_event',
    getEvent = 'get_event',
    openEvents = 'open_event',
    upsertNote = 'upsert_note',
    removeNote = 'remove_note',
    listNotes = 'list_notes',
    getNote = 'get_note',
    getTabId = 'get_current_tab_id',
    loadNoteEditAttachment = 'load_note_edit_attachment',
    loadDocNoteData = 'load_note_edit_data',
    getTaxonomy = 'get_taxonomy',
    removeTaxonomy = 'remove_taxonomy',
    upsertTaxonomy = 'upsert_taxonomy',
    listTaxonomies = 'list_taxonomies',
    openNoteEditor = 'open_note_editor',
    resetPopupState = 'reset_popup_state',
    showPopupErrors = 'show_popup_errors',
    hitCachedImage = 'hit_cached_image',
    hitCachedContext = 'hit_cached_context',
    openApplication = 'open_application',
    togglePopupState = 'toggle_popup_state',
    upsertBookmark = 'upsert_bookmark',
    removeBookmark = 'remove_bookmark',
    listBookmarks = 'list_bookmarks',
    getBookmark = 'get_bookmark',
    openBookmarks = 'open_bookmarks',
    upsertWidget = 'upsert_widget',
    getWidget = 'get_widget',
    upsertWorldClock = 'upsert_world_clock',
    removeWorldClock = 'remove_world_clock',
    listWorldClocks = 'list_world_clocks',
    getWorldClock = 'get_world_clock',
    openWorldClocks = 'open_world_clocks',
    syncGet = 'sync_get',
    syncSet = 'sync_set',
    localGet = 'local_get',
    localSet = 'local_set',
    upsertBackground = 'upsert_background',
    removeBackground= 'remove_background',
    listBackground = 'list_background',
    getBackground= 'get_background',
    insertTaxonomy = 'insert_taxonomy',
    insertNote = 'insert_note',
    verifyLicenseKey = 'verify_license_key',
    createAccount = "create_account",
    initAccount = "init_account",
    signIn = "sign_in",
    signOut = "sing_out",
}