import {Requests} from '../api/requests';

export const END_OF_DATA_POINTER = 'end_of_data';
export const POINTER_OFFSET = 1;

export type RequestMeta = {
    requestTabId?: number,
}

export type ResponseMeta = RequestMeta;

export type ResponseErrorType = 'fatal' | 'warning' | 'notice';

export type ResponseError = {
    message: string,
    stack: string,
    type: ResponseErrorType,
}

export type Message = {
    message: string,
    code?: number|string,
}

export type RequestRemoveDoc = {
    _id: string,
}

export type RequestUpsertDoc = any;

export type RequestGetDoc = RequestRemoveDoc;


export type RequestListDocs = {
    doc_type?: DocumentType[],
    collection?: string,
    sortBy?: 'created_at'|'modified_at'|'starting_at'|'ending_at'
    sortDirection?: 'asc'|'desc'
    limit: number,
    pointer?: string,
    deleted?: boolean,
    query?: string,
    status?: any,
    starting_at?: string,
    context?: string,
}

export type RequestListCalenderItems = {
    sortBy?: 'created_at'|'modified_at'|'starting_at'|'ending_at',
    starting_at?: string,
    ending_at?: string,
} & RequestListDocs

export type RequestOpenEditor = {
    data: { editorType: DocType },
    attachments?: Attachment[]
}

export type RequestDataTypes = RequestUpsertDoc|RequestGetDoc|RequestRemoveDoc|RequestListDocs|RequestRemoveTaxonomy|RequestUpsertTaxonomy|RequestGetTaxonomy|RequestListTaxonomies|RequestOpenEditor;

export enum DocumentType {
    NOTE = 'note',
    EVENT = 'event',
    TASK = 'task',
    BOOKMARK = 'bookmark',
    READING_ITEM = 'reading-item',
    CONFIG = 'config',
    TAXONOMY = 'taxonomy',
    SCREEN = 'screen',
    COLUMN = 'column',
    WIDGET = 'widget',
    WORLD_CLOCK = 'world_clock',
    BACKGROUND = 'background',
    GOAL = 'goal',
}

export enum TaskStatus {
    COMPLETED = "completed",
    NO_STARTED = "not_started",
    IN_PROGRESS = "in_progress"
}

export enum GoalStatus {
    COMPLETED = "completed",
    NO_STARTED = "not_started",
    IN_PROGRESS = "in_progress"
}

export type Document = {
    _id: string,
    _rev?: string,
    doc_type?: DocumentType;
}

export type Request<T> = {
    _id: string,
    type: Requests,
    generic: 'response'|'request'|'message',
    meta?: RequestMeta,
    data?: T,
    attachments?: Attachment[],
}


export type Response = {
    _id: string,
    type: Requests,
    generic: 'response'|'request'|'message',
    meta?: ResponseMeta,
    error?: ResponseError,
    data?: Record<string, any>
}

export type DocType = 'text'|'image';

export type Doc = {
    name: string,
    description?: string,
    content?: string,
    index_content?: string,
    created_at: string,
    color: string,
    modified_at: string,
    deleted_at?: string,
    collection?: Taxonomy,
    context?: string,
    tags?: Taxonomy[],
    _attachments: Record<string, Attachment>,
    type: DocType,
} & Document;

export type BlobAttachment = {
    _id: string,
    content_type: string,
    data: Blob
}

export type Attachment = {
    _id?: string,
    content_type: string,
    data: string,
    digest?: string
}

export type Task = {
    deadline?: string
    status: TaskStatus
    starting_at: string
    ending_at?: string
    completed_at?: string,
} & Doc & Document

export type Event = {
    starting_at: string
    ending_at?: string
    location?: string
} & Doc

export type Goal = {
    deadline?: string
    status: GoalStatus
    starting_at: string
    ending_at?: string
    completed_at?: string,
    items: Doc[]
} & Doc


export type TaxonomyType = 'collection' | 'tag';

export type Taxonomy = {
    type: TaxonomyType,
    name: string,
    color?: string,
    modified_at?: string,
    entities?: string[]
    context?: string,
} & Document

export type Entity = {
    _id: string,
    name: string
}

export type RequestRemoveTaxonomy = {
    _id: string,
};

export type RequestUpsertTaxonomy = Taxonomy;

export type RequestGetTaxonomy = RequestRemoveTaxonomy;


export type RequestListTaxonomies = {
    type: TaxonomyType,
    query?: string,
    context?: string,
};

export type ContainerType = 'centered' | 'columns';

export type Container = {
    type: ContainerType,
    columnIds: string[],
    columns: ScreenColumn[]
} & Document;

export enum ScreenColumnSize {
    NARROW = 3,
    WIDE = 5,
    WIDER = 7,
};

export type ScreenColumn = {
    size: ScreenColumnSize,
    widgetIds: string[]
    widgets: Widget[],
};

export enum WidgetTypes {
    BOOKMARKS = 'bookmarks',
    WORLD_CLOCK = 'world-clock',
    CLOCK = 'clock',
    STICKY_NOTE = 'sticky-note',
    MENU_CLOCK = 'menu-clock',
    MOTTO = 'motto',
    NONE = 'none',
}

export enum WidgetItemType {
    NOTE = 'note',
    TASK = 'task',
    EVENT = 'event',
    BOOKMARK = 'bookmark',
    READING_ITEM = 'reading-item',
    CLOCK = 'clock',

}

export type WidgetItem<T> = Document & T & {
    doc_type: WidgetItemType,
};

export type Widget = {
    _id: string,
    name: string,
    description: string,
    context: string,
    type: WidgetTypes,
    options: Record<string, any>,
    items:  string[][]
};

export type Bookmark = {
    name: string,
    url: string,
    description?: string,
    _content?: string,
    modified_at: string,
    collection?: Taxonomy,
    tags?: Taxonomy[],
    _attachments?: Record<string, Attachment>,
    isDefault?: boolean,
} & Document;



export type WorldClock = {
    timezone: string,
    modified_at: string,
    collection?: Taxonomy,
} & Document;

export type ScreenRow = {
    column1: string,
    column2: string,
    allowed: Widget[],
}

export type ColumnsScreen = {
    topArea: ScreenRow[],
    mainArea: ScreenRow[],
}

export type Vault = {
    cluster: Cluster,
    compact_running: boolean,
    db_name: string,
    disk_format_version: number,
    doc_count: number,
    doc_del_count: number,
    instance_start_time: number,
    purge_seq: string,
    size: VaultSize,
    update_seq: string,
    props: VaultProps
}

export type VaultProps = {
    partitioned: boolean
}

export type VaultSize = {
    active: number,
    external: number,
    file: number,
}

export type Cluster = {
    n: number,
    q: number,
    r: number,
    w: number,
}

export type AccountData = {
    success: boolean,
    token: string,
    message: string
    vault: Vault

}