import React, {ChangeEvent, useCallback, useState} from 'react';
import {Taxonomy} from '../db/types';
import {useResponseErrors} from './useResponseErrors';
import configs from '../configs';

export type Pointer = {value: null|string, type: 'init'|'search'|'collection', limit?: number};


export type Props = {
    listCallback: (options: {query: string, collection: string, pointer: Pointer}) => any;
}


export default function useListItems<T>({listCallback}: Props) {
    const [items, setItems] = useState<T[]>([]);
    const [pointer, setPointer] = useState<Pointer>({value: null, type: 'init'});
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [collection, setCollection] = useState('');
    const [query, setQuery] = useState('');
    const [prevPointer, setPrevPointer] = useState<Pointer>({value: null, type: 'init'});

    const {RenderedErrors, addError, clearErrors, hasErrors} = useResponseErrors();

    const reset = useCallback(() => {
        setPointer({value: null, type: 'init'});
        setCollection('');
        setQuery('');
        setItems([])

    }, [items, pointer, query, collection, status])

    const refresh = useCallback(() => {
        setItems(() => []);
        setPointer({...prevPointer});
        onLoadMore();

    }, [items, pointer, query, collection, status, prevPointer])

    const onLoadMore = useCallback(async () => {
        if (isLoadingMore) {
            return;
        }

        clearErrors();
        console.log('in useListItems 1')
        let oldPointer = {...pointer};
        const oldItems = [...items];
        if (!collection && !query && pointer.type !== 'init') {
            oldPointer = {type: 'init', value: null, limit: configs.application.cardsPerPage};
        }

        try {
            setIsLoadingMore(true);
            const {items: moreItems, pointer: newPointerValue} = await listCallback({query, collection, pointer});
            console.log('in useListItems')
            console.log(items)
            setItems([...oldItems, ...moreItems]);
            setPointer({...oldPointer, value: newPointerValue});
        } catch(error: any) {
            addError(error);
        }


        setIsLoadingMore(false);
    }, [items, pointer, query, collection])


    const onSearchChange = async (searchQuery: string) => {
        clearErrors();
        if (searchQuery && searchQuery?.length < 3) {
            setQuery(searchQuery);
            return;
        }

        setPrevPointer({...pointer});

        let pointerValue = pointer.value;

        if (pointer.type !== 'search' || searchQuery !== query) {
            pointerValue = null;
        }

        const oldPointer: Pointer = {type: 'search', value:  pointerValue, limit: configs.application.searchCardsPerPage}

        try {
            setIsLoadingMore(true);
            const {items: moreItems, pointer: newPointerValue} = await listCallback({query: searchQuery, collection, pointer:oldPointer});

            if (query === searchQuery && pointer.type === 'search' && pointer.value != newPointerValue) {
                setItems([...items, ...moreItems]);
            } else {
                setItems([...moreItems]);
            }

            setPointer({...oldPointer, value: newPointerValue});
        } catch (error: any) {
            addError(error);
        }


        setQuery(searchQuery);
        setIsLoadingMore(false);
    }

    const onCollectionSelectChange = async (event: ChangeEvent<HTMLSelectElement>) => {
        const oldCollection = collection;
        clearErrors();
        setCollection(event.target.value);
        setPrevPointer({...pointer});

        let pointerValue = pointer.value;

        if (pointer.type !== 'collection' || oldCollection !== event.target.value) {
            pointerValue = null;
        }

        try {

            setIsLoadingMore(true);
            const oldPointer: Pointer = {type: 'collection', value: pointerValue, limit: configs.application.collectionCardsPerPage}
            const {items: newItems, pointer: newPointerValue} = await listCallback({query, collection: event.target.value, pointer: oldPointer});

            if (pointer.type === 'collection' && event.target.value === oldCollection) {
                setItems([...items, ...newItems]);
            } else {
                setItems([...newItems]);
            }

            setPointer({...oldPointer, value: newPointerValue});
        } catch(error: any) {
            addError(error);
        }

        setIsLoadingMore(false);
    }


    return {
        RenderedErrors,
        addError,
        clearErrors,
        hasErrors,
        onLoadMore,
        pointer,
        setPointer,
        isLoadingMore,
        setIsLoadingMore,
        items,
        setItems,
        onSearchChange,
        query,
        onCollectionSelectChange,
        collection,
        reset,
        refresh
    }
}