import configs from '../../configs';

export function uploadFileValidation(file: File) {
    if (file.size > configs.dropzone.maxFileSize) {
        return {
            code: "image-too-large",
            message: `The image is larger than ${configs.dropzone.maxFileSize/(1000*1000)}MB`,
        };
    }

    return null
}

export function formattedBytes(x){
    const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    let l = 0, n = parseInt(x, 10) || 0;

    while(n >= 1024 && ++l){
        n = n/1024;
    }

    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export function docValidation(doc: any, attachments?: any): [boolean, string] {
    let attachmentsSize = 0;

    if (attachments) {
        attachmentsSize = sizeOf(attachments) / 3;
    }

    const docSize = sizeOf(doc);


    if (attachmentsSize > configs.validation.docs.maxAttachmentsSize) {
        return [false, `Too big attachments (${formattedBytes(attachmentsSize)}). The files' size must be less than ${formattedBytes(configs.validation.docs.maxAttachmentsSize)}`]
    }



    if (docSize > configs.validation.docs.maxDocumentSize) {
        return [false, `Too big document (${formattedBytes(docSize)}). It's size must be less than ${formattedBytes(configs.validation.docs.maxDocumentSize)}`]
    }

    return [true, ""];
}

export function sizeOf (doc: any): number {

    var objectList:any[] = [];
    var stack = [ doc ];
    var bytes = 0;

    while (stack.length) {
        var value: any = stack.pop();

        if (typeof value === 'boolean') {
            bytes += 4;
        } else if (typeof value === 'string') {
            bytes += value.length * 2;
        }
        else if (typeof value === 'number') {
            bytes += 8;
        } else if (typeof value === 'object' && objectList.indexOf(value) === -1) {
            objectList.push(value);

            for( var i in value ) {
                stack.push(value[ i ]);
            }
        }
    }
    return bytes;
}