import PouchDB from 'pouchdb';
import inMemory from 'pouchdb-adapter-memory';
import * as devConsole from '../utils/common/devConsole';
import {Attachment} from './types';

PouchDB.plugin(inMemory);
export const inMemoryCache = new PouchDB('sv_cache', {auto_compaction: true, adapter: 'memory'});

export const CACHE_IMAGE_KEY = 'base64_image';
export const CACHE_CONTEXT_DATA = 'cache_context_data';
export const CACHE_SCREEN_DATA = 'cache_screen_data';
export const CACHE_BACKGROUND = 'cache_background';


export const cacheData = async(key: string, data: any) => {
    let doc: any = {_id: key};

    try {
        doc = await inMemoryCache.get(key);
    } catch (error: any) {
        devConsole.dir(error);
    }

    doc.data = data

    await inMemoryCache.put(doc);
}

export const getCachedData = async (key: string) => {
    try {
        const doc:any = await inMemoryCache.get(key);
        return doc.data;
    } catch {
        return;
    }
}

export const cacheScreenData = async (data?: any) => {
    await cacheData(CACHE_SCREEN_DATA, data);
}

export const getCacheScreenData = async() => {
    return getCachedData(CACHE_SCREEN_DATA)
}


export const cacheContextData = async (data?: {attachment?: Attachment, content?: string}) => {
    await cacheData(CACHE_CONTEXT_DATA, data);
}

export const getCachedContextData = async() => {
   return getCachedData(CACHE_CONTEXT_DATA);
}

export const hitCachedContextData= async() => {
    try {
        const doc:any = await inMemoryCache.get(CACHE_CONTEXT_DATA);
        await cacheContextData(undefined)
        return doc.data;
    } catch {
        return;
    }
}

export const cacheImage = async (attachment?: Attachment) => {
    let doc: any = {_id: CACHE_IMAGE_KEY};

    try {
        doc = await inMemoryCache.get(CACHE_IMAGE_KEY);
    } catch (error: any) {
        devConsole.dir(error);
    }

    doc.data = attachment;

    await inMemoryCache.put(doc);
}

export const getCachedImage = async() => {
    try {
        const doc:any = await inMemoryCache.get(CACHE_IMAGE_KEY);
        return doc.data;
    } catch {
        return;
    }
}

export const hitCachedImage = async() => {
    try {
        const doc:any = await inMemoryCache.get(CACHE_IMAGE_KEY);
        await cacheImage(undefined)
        return doc.data;
    } catch {
        return;
    }
}