import {Outlet, useLoaderData, useLocation, useMatch, useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {
    routeBookmarksEdit,
    routeEntityTaxonomiesEdit, routeEntityTaxonomiesList,
    routeNotesEdit,
    routeNotesList,
} from '../../../routes';
import LayoutNav, {LayoutNavItem} from '../../Common/LayoutNav/LayoutNav';
import ApplicationLayout from './ApplicationLayout';
import {makeRouteMatch} from '../../Mobile/Menu/Menu';
import {Goal} from '../../../db/types';
import GoalHeader from '../../Common/Context/GoalHeader/GoalHeader';

export type Props = DefaultProps;

export function getContentAwareNavLink(url?: string, context?: string): LayoutNavItem {
    if (url?.includes(routeEntityTaxonomiesList(url,'collection', context)) || url?.includes(routeEntityTaxonomiesEdit(url,'collection', context))) {
        return {text: 'Add Collection', to: routeEntityTaxonomiesEdit(url,'collection', context)};
    }

    if (url?.includes(routeEntityTaxonomiesList(url,'tag')) || url?.includes(routeEntityTaxonomiesEdit(url,'tag')) ) {
        return {text: 'Add Tag', to: routeEntityTaxonomiesEdit(url,'tag', context)};
    }

    return {text: 'Add Note', to: routeNotesEdit('text', undefined, context)};
}

export function getNavItems(path: string, context): LayoutNavItem[] {
    return [
        {
            to: routeNotesList(context),
            text: 'Notes'
        },
        {
            to: routeEntityTaxonomiesList(path,'collection', context),
            text: 'Collections'
        },
        {
            to: routeEntityTaxonomiesList(path,'tag', context),
            text: 'Tags'
        },
    ];

}


export default function BookmarksLayout({style, className}: Props) {
    const {pathname} = useLocation();
    const match = useMatch(makeRouteMatch(pathname));
    const context = match?.params?.param2 || 'all';

    const [addItem, setAddItem] = useState(getContentAwareNavLink(pathname, context));
    const {goal} = useLoaderData() as {goal?: Goal};


    useEffect(() => {
        setAddItem(getContentAwareNavLink(pathname, context));
    }, [pathname]);

    return (
        <ApplicationLayout className={classnames(className)} style={{...style}}>
            {goal && <GoalHeader goal={goal} />}
            <LayoutNav addItem={addItem} items={getNavItems(pathname, context)} />
            <Outlet />
        </ApplicationLayout>
    )
}