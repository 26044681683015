import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {filterTaxonomiesByEntities} from '../../utils/common/taxonomies';

export default async function listBookmarksLoader({params}) {
    const collectionsResponse = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listTaxonomies,
        data: {
            type: 'collection'
        }
    });

    const collections = filterTaxonomiesByEntities( collectionsResponse.data?.taxonomies || [] , ['all', 'bookmarks']);

    return {collections,  routeContext: params.context, error: collectionsResponse.error};
}