import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';

export default async function contextLoader({params})  {
    if (!params.context || params.context === 'all') {
        return {};
    }

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getGoal,
        data: {_id: params.context as string}
    });

    return {goal: response.data || null, routeContext: params.context, error: response.error};
}