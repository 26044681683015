import React, {useCallback, useEffect, useState} from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import './ViewCalendarScreen.scss'
import useAppTitle from '../../../../../hooks/useAppTitle';
import {useLoaderData} from 'react-router-dom';
import {ResponseError} from '../../../../../db/types'
import ViewItemModal from '../../../../Common/Item/ViewItemModal/ViewItemModel';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {useResponseErrors} from '../../../../../hooks/useResponseErrors';
const localizer = momentLocalizer(moment)



const startAccessor = (event: any) => {
    if (event.starting_at) {
        return new Date(event.starting_at);
    }

    if (event.deadline) {
        return new Date(event.deadline);
    }

    return new Date(event.created_at);
}

const endAccessor = (event: any) => {
    if (event.ending_at) {
        return new Date(event.ending_at);
    }

    let date = new Date(event.created_at)

    if (event.starting_at) {
        date = new Date(event.starting_at);

    }

    if (event.deadline) {
        date = new Date(event.starting_at);
    }

    date.setHours(date.getHours() + 1);
    return date;
}

export default function ViewCalendarScreen() {

    const {items, error, routeContext} = useLoaderData() as {items: any, error: ResponseError, routeContext: string};
    const [item, setItem] = useState<any>();

    const {RenderedErrors, clearErrors, addError, errors} = useResponseErrors()
    const [loadedItems, setLoadedItems] = useState<any>(null)

    useAppTitle('Calendar');

    const onSelectEvent = useCallback(
        (event) => setItem(event),
        []
    )

    const onRangeChange = useCallback(
         (range) => {
            clearErrors();
            let starting_at = new Date();
            let ending_at = new Date();

            if (Array.isArray(range) && range.length < 2) {
                starting_at = range[0];
                ending_at = new Date(range[0].toISOString());
                ending_at.setHours(23);
                ending_at.setMinutes(59);
                ending_at.setSeconds(59);
            } else if (Array.isArray(range)) {
                starting_at = range[0];
                ending_at = range[range.length - 1];
            }

            if (range.start) {
                starting_at = range.start;
                ending_at = range.end;
            }

            sendRequest({
                _id: getTimestampId(),
                generic: 'request',
                type: Requests.listCalendarItems,
                data: {
                    starting_at: starting_at,
                    ending_at: ending_at,
                    doc_type: ['task', 'event', 'note', 'goal'],
                    context: routeContext,
                }
            }).then(response => {
                if (response.error) {
                    addError(response.error)
                    return;
                }

                if (!response.data) {
                    addError(
                        {
                            message: "Can't load events",
                            stack: "",
                            type: 'warning'
                        }
                    )
                    return;
                }

                setLoadedItems(response.data.items)
            }).catch(error => {
                console.error(error);
            });



        }
    , [errors, clearErrors])

    return (
        <div className="calendar-wrapper pb-4">
            <RenderedErrors />
            <Calendar
                dayLayoutAlgorithm="no-overlap"
                localizer={localizer}
                events={loadedItems || items}
                startAccessor={startAccessor}
                endAccessor={endAccessor}
                titleAccessor="name"
                allDayAccessor="all_day"
                popup={true}
                selectable={true}
                onSelectEvent={onSelectEvent}
                onRangeChange={onRangeChange}
                style={{height: 'auto', minHeight: '100vh'}}
            />

            {item && <ViewItemModal show={!!item} item={item} onClose={() => setItem(null)} />}
        </div>
    );
}