import {Outlet, useLoaderData} from 'react-router-dom';
import React from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {Col, Row} from 'react-bootstrap';
import ApplicationMenu from '../../Common/ApplicationMenu/AppliatoinMenu';
import {Goal} from '../../../db/types';
import GoalHeader from '../../Common/Context/GoalHeader/GoalHeader';

export type Props = DefaultProps;

export default function CalendarLayout({style, className}: Props) {
    const {goal} = useLoaderData() as {goal?: Goal};

    return (
            <div className={classnames("application-layout", className)} style={{...style}}>
                <Row className="w-100">
                    <Col xs={1} className="position-relative">
                        <ApplicationMenu />
                    </Col>
                    <Col xs={11} style={{minHeight: "100vh"}}>
                        {goal && <GoalHeader goal={goal} />}
                        <Outlet />
                    </Col>
                </Row>
            </div>
        );
}