import React, {PropsWithChildren, useCallback, useEffect} from 'react'
import {FileRejection, useDropzone} from 'react-dropzone'
import {Attachment} from '../../../db/types';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import configs from '../../../configs';
import {uploadFileValidation} from '../../../utils/common/sizes';

export type Props = {
    onFileReady: (file) => void
    onFileRejection?: (fileRejections: FileRejection[]) => void,
} & DefaultProps

export default function Dropzone({onFileReady, style, className, children, onFileRejection}: PropsWithChildren<Props>) {
    const onDrop = useCallback(async (acceptedFiles) => {
        const reader = new FileReader()
        const contentType = acceptedFiles[0].type;
        const filename = acceptedFiles[0].name;

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const file: Attachment = {
                data: reader.result as string,
                content_type: contentType,
                _id: filename,
            };

            onFileReady(file);
        }
        reader.readAsDataURL(acceptedFiles[0])
    }, [])
    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone(
        {
            onDropAccepted: onDrop,
            accept: configs.dropzone.accept,
            maxFiles: configs.dropzone.maxFiles,
            validator: uploadFileValidation,
        })

    useEffect(() => {
        if (fileRejections?.length > 0 && onFileRejection) {
            onFileRejection(fileRejections);
        }
    }, [fileRejections]);

    return (
        <div {...getRootProps({className: classnames("dropzone d-flex justify-content-center align-items-center", className)})} style={style}>
            <input {...getInputProps()} />
            {children}
            {
                !children &&

                (isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p className="text-center">Drag &apos;n&apos; drop some files here, <br /> or <br />Click to select files</p>)
           }
        </div>
    )
}

