import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Doc, END_OF_DATA_POINTER, Task, TaskStatus, Taxonomy} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import CollectionSelect from '../../../../Common/CollectionSelect/CollectionSelect';
import TaskCard from '../../../../Common/Cards/TaskCard/TaskCard';
import useAppTitle from '../../../../../hooks/useAppTitle';
import useListItemsWithStatus, {Pointer} from '../../../../../hooks/useListItemsWithStatus';
import ViewItemModal from '../../../../Common/Item/ViewItemModal/ViewItemModel';
import {getLocalDate} from '../../../../../utils/common/datetime';
import classnames from 'classnames';


export const makeListTasks = (context: string) => {
    return async (options: {query?: string, collection?: string, status?: string, pointer?: Pointer}) => {
        const response = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listTasks,
            data: {
                collection: options.collection,
                query: options.query,
                pointer: options.pointer?.value,
                limit: 100,
                status: options.status,
                sortBy: 'starting_at',
                sortDirection: options.status == TaskStatus.IN_PROGRESS ? 'asc' : 'desc',
                context: context,
            }
        });

        if (response.error || !response.data) {
            throw response.error;
        }

        return response.data?.items ? {...response.data} : {items: []} as unknown as {items: any[], pointer: string|null};
    }
}

export default function ListTasksScreen() {

    const data = useLoaderData() as {collections: Taxonomy[], routeContext: string};

    const listTasks = useMemo(() => {
        return makeListTasks(data.routeContext);
    }, [data])

    const [pickedCard, setPickedCard] = useState<Doc|null>(null);
    const {items, refresh, reset, onStatusChange, collection, query, onCollectionSelectChange, onSearchChange, onLoadMore, RenderedErrors, hasErrors, pointer, isLoadingMore} = useListItemsWithStatus<Task>({listCallback: listTasks, defaultStatus: TaskStatus.IN_PROGRESS});

    useAppTitle('Tasks');


    const showLoadMore = pointer.value !== END_OF_DATA_POINTER && pointer.value;

    useEffect(() => {
        onLoadMore();
    }, [])


    const onCardClick = useCallback((card) => {
        setPickedCard(card);
    } , []);

    let groupDates: string[] = [];


    const render = (
        <Row className="m-0 application pb-2">
            <Col xs={12}>
                <SearchBar
                    onClick={(query) => onSearchChange(query)}
                    onBlur={(query) => onSearchChange(query)}
                    className="mt-3 mb-1 default-background" query={query}
                />
                 <CollectionSelect value={collection} className="mb-2 default-background" onChange={onCollectionSelectChange} collections={data.collections} />
                <div className="d-flex justify-content-end mb-4">
                    <Form.Check type="switch">
                        <Form.Check.Input
                            type="checkbox"
                            className="bg-secondary border-0"
                            value={TaskStatus.COMPLETED}
                            onChange={(event) => {
                                if (event.target.checked) {
                                    event.target.value = TaskStatus.COMPLETED;
                                } else {
                                    event.target.value = TaskStatus.IN_PROGRESS;
                                }

                                reset();
                                onStatusChange(event);
                            }}
                        />
                        <Form.Check.Label className="text-white">Completed</Form.Check.Label>
                    </Form.Check>
                </div>

                {hasErrors && <RenderedErrors className="mb-2" />}
                {isLoadingMore && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}

                {pickedCard && <ViewItemModal show={!!pickedCard} item={pickedCard} onClose={() => setPickedCard(null)} onChange={() => refresh()} />}
                {items.map((task, index) => {
                    const startingAt = getLocalDate(task.starting_at);
                    const render = (
                        <div key={`${task._id}`}>
                            {!groupDates.includes(startingAt) && <div className={classnames("h6 text-white", {"mt-5": index > 0})}>{startingAt}</div>}
                            <TaskCard task={task} onClick={() => onCardClick(task)} className="mb-2" />
                        </div>
                    );

                    groupDates.push(startingAt);
                    return render;
                })}
                {!isLoadingMore && items.length < 1 && <div className="text-white mb-2">No tasks were found...</div>}
                {showLoadMore && <PrimaryButton spinnerVariant="light" className="w-100" variant="outline-primary" isProcessing={isLoadingMore} onClick={onLoadMore}>Load More</PrimaryButton>}
            </Col>
        </Row>
    );

    groupDates = [];
    return render;
}