import React, {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {Attachment, DocType, Task, TaskStatus, Taxonomy} from '../../../../../db/types';
import {getDataUrlFromAttachment} from '../../../../../utils/common/base64';
import {useLoaderData, useNavigate} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import Icon from '../../../../Common/Icon/Icon';
import SimpleEditor from '../../../../Common/SimpleEditor/SimpleEditor';
import {routeMessages, routeTasksList} from '../../../../../routes';
import configs from '../../../../../configs';
import {sanitizeContent} from '../../../../../utils/common/sanitize';
import convertToHTML from 'draftjs-to-html';
import useAppTitle from '../../../../../hooks/useAppTitle';
import CollectionField from '../../../../Common/Form/CollectionField/CollectionField';
import TagsField from '../../../../Common/Form/TagsField/TagsField';
import {getDateTimeFieldValue, getModifiedAt} from '../../../../../utils/common/datetime';
import {useResponseErrors} from '../../../../../hooks/useResponseErrors';
import HelpTooltip from '../../../../Common/HelpTooltip/HelpTooltip';
import OptionalField from '../../../../Common/Form/OptionalField/OptionalField';



export type EditData = {
    _id?: string,
    name: string,
    attachment?: Attachment,
    deadline?: string,
    color: string,
    created_at?: string,
    modified_at?: string,
    collection?: Taxonomy,
    status: string,

}

export default function EditTaskScreen() {
    const [data, setData] = useState<EditData>({name: '', color: '#000000', status: TaskStatus.IN_PROGRESS});
    const [isSaving, setIsSaving] = useState(false);
    const [collection, setCollection] = useState<Taxonomy|undefined>();
    const [tags, setTags] = useState<Taxonomy[]>([]);
    const [content, setContent] = useState<string>('');
    const [startingAt, setStartingAt] = useState<string>('');
    const [endingAt, setEndingAt] = useState<string>('');

    const navigate = useNavigate();
    const {task, editorType, routeContext} = useLoaderData() as {task: Task, editorType: DocType, routeContext: string};
    const startingAtRef = useRef<HTMLInputElement|null>(null);
    const endingAtRef = useRef<HTMLInputElement|null>(null);
    const {clearErrors, addError, RenderedErrors} = useResponseErrors();

    useAppTitle('Tasks', task ? 'Edit Task' : 'New Task');

    const [visibility, setVisibility] = useState({
        endingAt: !!task?.ending_at && task?.ending_at !== task?.starting_at,
        content: !!task?.content,
        tags: tags.length > 0,
    });


    useEffect(() => {
        setStartingAt(getDateTimeFieldValue(new Date()));

        if (!task) {
            return;
        }

        const newData = {...task} as EditData;
        if (task._attachments) {
            const values = Object.values(task._attachments) as Attachment[];

            if (values[0]) {
                newData.attachment = {...values[0], data: getDataUrlFromAttachment(values[0] as Attachment)}
            }
        }

        setData(newData);

        if (task.content) {
            setContent(task.content);
        }

        if (task.collection) {
            setCollection(task.collection);
        }

        if (task.tags) {
            setTags(task.tags);
        }

        if (task.starting_at) {
            setStartingAt(task.starting_at);
        }

        if (task.ending_at) {
            setEndingAt(task.ending_at);
        }

    }, [task])


    const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        clearErrors();
        event.preventDefault();
        setIsSaving(false);
        const createdAt = data.created_at || new Date().toISOString();
        const modifiedAt = getModifiedAt(data?._id);

        const attachments: Attachment[] = [];

        if (data.attachment) {
            attachments.push(data.attachment);
        }


        const response = await sendRequest({
            _id: getTimestampId(),
            type: Requests.upsertTask,
            generic: 'request',
            data: {
               _id: data._id || getTimestampId(),
                name: data.name,
                content: sanitizeContent(content),
                color: data.color,
                created_at: createdAt,
                modified_at: modifiedAt,
                collection: {...data.collection, ...collection},
                tags: tags,
                type: editorType,
                starting_at: startingAt,
                ending_at: endingAt || startingAt,
                status: data.status || TaskStatus.IN_PROGRESS,
                context: task?.context || routeContext,
            },
            attachments: attachments
        });

        setIsSaving(false);
        if (response?.data?.status === 'success') {
            setData({name: '', color: '#000000', status: TaskStatus.IN_PROGRESS});
            setContent('');
            navigate(routeMessages('successful-task-saving', task?.context || routeContext))
        }

        if (response.error) {
            addError(response.error);
        }

    }, [data, collection, tags, content, startingAt, endingAt, addError, clearErrors, task, routeContext]);

    const onCancel = useCallback(() => {
        if (isSaving) {
            return;
        }

        navigate(routeTasksList(task?.context || routeContext));
    }, [task, routeContext])

    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <Card className="bg-transparent border-0 w-100">
                <Card.Body>
                    <Form onSubmit={onSubmit}>
                        <div className="d-flex justify-content-start align-items-center mb-3">
                            <div className="w-100">
                                <FormGroup className="mb-2">
                                    <FormLabel htmlFor="task_name" className="text-white">Title</FormLabel>
                                    <FormControl
                                        type="text"
                                        className="default-background"
                                        maxLength={configs.validation.docs.name.maxLength}
                                        minLength={configs.validation.docs.name.minLength}
                                        name="task_name"
                                        value={data.name}
                                        onChange={(event) => setData((data) => ({...data, name: event.target.value}))}
                                        required={true}
                                    />
                                </FormGroup>
                                <CollectionField onChange={setCollection} value={collection} entities={['all', 'tasks']} />

                            </div>
                        </div>

                        <FormGroup className="d-flex justify-content-between align-items-center my-2">
                            <FormLabel htmlFor="color" className="text-white">
                                Color
                                <HelpTooltip placement="top" className="ms-2 default-background" style={{cursor: 'pointer'}}>The color is inherited from the chosen collection</HelpTooltip>
                            </FormLabel>
                            <FormControl
                                type="color"
                                name="color"
                                readOnly={true}
                                value={collection?.color || configs.cards.defaultColor}
                                onChange={() => {
                                    //don't change anything;
                                }}
                            />
                        </FormGroup>

                        <FormGroup className="my-2">
                            <FormLabel htmlFor="starting_at" className="text-white">{visibility.endingAt ? 'Starting At' : 'Date'}</FormLabel>
                            <div className="d-flex align-items-center">
                                <FormControl required={true} ref={startingAtRef} className="default-background" type="datetime-local" name="starting_at" value={startingAt} onChange={(event) => setStartingAt(() => event.target.value)} placeholder="Set a starting date..."  />
                                <Button variant="light" className="default-background border-0" style={{width: '48px', height: '36px'}} onClick={() => startingAtRef?.current && startingAtRef.current?.showPicker()}>
                                    <Icon type="ti-calendar" />
                                </Button>
                            </div>
                        </FormGroup>

                        <OptionalField
                            onClick={() => setVisibility({...visibility, endingAt: true})}
                            label="Ending At"
                            show={visibility.endingAt}
                        >

                            <FormGroup className="my-2">
                                <FormLabel htmlFor="ending_at" className="text-white">Ending At</FormLabel>
                                <div className="d-flex align-items-center">
                                    <FormControl ref={endingAtRef} className="default-background" type="datetime-local" name="ending_at" value={endingAt} onChange={(event) => setEndingAt(() => event.target.value)} placeholder="Set an ending date..."  />
                                    <Button variant="light" className="default-background border-0" style={{width: '48px', height: '36px'}} onClick={() => endingAtRef?.current && endingAtRef.current?.showPicker()}>
                                        <Icon type="ti-calendar" />
                                    </Button>
                                </div>
                            </FormGroup>

                        </OptionalField>


                        <OptionalField
                            onClick={() => setVisibility({...visibility, content: true})}
                            label="Description"
                            show={visibility.content}
                        >

                            <FormGroup>
                                <FormLabel className="text-white">Description</FormLabel>
                                <SimpleEditor
                                    className="default-background"
                                    value={task?.content || ''}
                                    onChange={(state) => {
                                        setContent(() => convertToHTML(state));
                                    }}
                                />
                            </FormGroup>
                        </OptionalField>

                        <OptionalField
                            onClick={() => setVisibility({...visibility, tags: true})}
                            label="Tags"
                            show={visibility.tags}
                        >

                            <TagsField tags={tags} setTags={setTags} className="mt-2" />
                        </OptionalField>

                        <div style={{backgroundColor: '333333'}}>
                            <PrimaryButton className="w-100 mt-3" isProcessing={isSaving} type="submit">
                                Save
                            </PrimaryButton>
                            <Button variant="outline-primary text-white" className="w-100 mt-2" onClick={onCancel} disabled={isSaving}>Cancel</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <RenderedErrors className="mt-2" />
        </div>
    );
}