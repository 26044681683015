import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';

export default async function viewNotesLoader({params})  {
    const collectionsResponse = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listTaxonomies,
        data: {
            type: 'collection'
        }
    });

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getNote,
        data: {_id: params._id as string}
    });

    return {card: response.data || null, collections: collectionsResponse.data?.taxonomies || [], routeContext: params.context, error: response.error};
}