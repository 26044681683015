import baseSanitize from 'sanitize-html';
import configs from '../../configs';

export function sanitizeDescription(html: string, sanitizeConf?: any) {
    return baseSanitize(html, {...configs.sanitize.description, ...sanitizeConf});
}

export function sanitizeContent(html: string, sanitizeConf?: any) {
    return baseSanitize(html, {...configs.sanitize.description, ...sanitizeConf});
}

export default function sanitizeExcerpt(html: string, sanitizeConf?: any) {
    return baseSanitize(html, {...configs.sanitize.excerpt, ...sanitizeConf});
}

