import React, {useEffect, useState} from 'react'
import PickModal from '../PickModal/PickModal';
import classnames from 'classnames';
import DefaultProps from '../../../types/DefaultProps';
import CreateAccount from './CreateAccount';
import SignIn from './SignIn';
import {AccountData} from '../../../db/types';
import Account from './Account';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {Requests} from '../../../api/requests';

export type Props = {
    show?: boolean
    onClose?: () => void
} & DefaultProps;


export type License = {success: boolean, message?: string, license_key: string, email: string};

export default function AuthModal({show, onClose, className, style}: Props) {

    const [data, setData] = useState<AccountData|null>(null);
    const [modal, setModal] = useState<'sign-up'|'sign-in'|'signed-in'>('sign-up')

    useEffect(() => {
        if (!data) {
            sendRequest({
                _id: getTimestampId(),
                generic: 'request',
                type: Requests.getAccountData,
            }).then(response => {
                if (!response.error && response.data) {
                    setModal('signed-in');
                    setData(() => {
                        return {...response.data} as AccountData
                    });
                }
            }).catch(error => console.error(error));
        }
    }, [data])

    return (<PickModal
        show={show}
        title="Cloud Account"
        className={classnames('auth-modal', className)}
        style={style}
        onClose={onClose}
    >

        {data && modal === 'signed-in' && <Account data={data} onClose={() => onClose && onClose()} />}
        {modal === 'sign-in' && <SignIn onCancel={() => onClose && onClose()} onSuccess={(data) => {
            setData(data)
            setModal('signed-in')
        }} message={data?.message} />}
        {modal === 'sign-up' && <CreateAccount onSuccess={(data) => setModal('sign-in')} onSignIn={() => setModal('sign-in')} />}
    </PickModal>);
}