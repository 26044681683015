import React, {useCallback, useRef, useState} from 'react';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {Button, Form} from 'react-bootstrap';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {Requests} from '../../../api/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {AccountData} from '../../../db/types';

export type Props = {
    onCancel: () => void
    onSuccess: (accountData: AccountData) => void
    message?: string
}

export default function SignIn({onCancel, onSuccess, message}: Props) {
    const {addError, RenderedErrors, clearErrors, hasErrors, errors} = useResponseErrors();
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const ref = useRef<HTMLFormElement|null>(null)

    const onSignIn = useCallback(() => {
        clearErrors()

        if (ref.current) {
            if (!ref.current?.reportValidity()) {
                return
            }
        }

        if (!email) {
            addError({message: "Please enter a valid email.", type: 'warning', stack: ""});
            return;
        }


        if (!password) {
            addError({message: "Please enter your password.", type: 'warning', stack: ""});
            return;
        }

        setIsProcessing(true)
        const data = {
            email: email,
            password: password,
        }

        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.signIn,
            data: {...data},
        }).then(response => {
            setIsProcessing(false)
            if (response.error) {
                addError(response.error);
                return;
            }

            window.location.reload();
            onSuccess(response.data as AccountData)
        }).catch(error => {
            setIsProcessing(false)
            console.error(error)
        });
    }, [ref, email, clearErrors, password, errors]);

    return (<>
        {!hasErrors && <p className="text-white">
            {message || `Sign in to activate the paid features.`}
        </p>}

        <RenderedErrors className="mb-3" />

        <Form ref={ref}>
            <Form.Group>
                <Form.Label className="text-white">Email</Form.Label>
                <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} required={true} placeholder="Enter you email" />
            </Form.Group>

            <Form.Group>
                <Form.Label htmlFor="password" className="text-white">Password</Form.Label>
                <Form.Control type="password" name="password" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter your password" required={true} />
            </Form.Group>

            <div className="d-flex justify-content-between mt-5">
                <PrimaryButton type="button" variant="secondary" onClick={onCancel}>Cancel</PrimaryButton>
                <PrimaryButton type="button" onClick={onSignIn} isProcessing={isProcessing}>Sign In</PrimaryButton>
            </div>
        </Form>
    </>);

}