export const getISODateFromId = (_id: string) => _id.split('ZR')[0] + 'Z';

export const getModifiedAt = (_id?: string) => {
    return new Date().toISOString();
}

export const getFormattedDate = (dateString: string, includeTime: boolean = false) => {
    if (dateString && dateString.includes('ZR')) {
        const date = new Date(dateString.split('ZR')[0] + 'Z');
        return !includeTime ? date.toLocaleDateString() : date.toLocaleString();
    }

    const date = new Date(dateString);
    return !includeTime ? date.toLocaleDateString() : date.toLocaleString();
}

export const getMonthStartDate = (): string => {
    const date = new Date();
    date.setDate(1);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0)
    return getDateTimeFieldValue(date);
}

export const getMonthEndDate = (): string => {
    const date = new Date();
    date.setDate(new Date(date.getFullYear(), date.getMonth(), 0).getDate());
    date.setHours(23);
    date.setMinutes(59)
    date.setSeconds(59)
    return getDateTimeFieldValue(date);
}

export const getWeekEndDate = (startDate?: number): string => {
    const date = new Date();

    if (startDate) {
        date.setDate(startDate - date.getDay() + 6)
    } else {
        date.setDate(date.getDate() - date.getDay() + 6)
    }

    date.setHours(23);
    date.setMinutes(59)
    date.setSeconds(59)
    return getDateTimeFieldValue(date);
}

export const getDayEndDate = (startDate?: number): string => {
    const date = new Date();

    if (startDate) {
        date.setDate(startDate)
    }

    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0)
    return getDateTimeFieldValue(date);
}

export const getDayStartDate = (startDate?: number): string => {
    const date = new Date();

    if (startDate) {
        date.setDate(startDate)
    }

    date.setHours(23);
    date.setMinutes(59)
    date.setSeconds(59)
    return getDateTimeFieldValue(date);
}

export const getWeekStartDate = (startDate?: number): string => {
    const date = new Date();

    if (startDate) {
        date.setDate(startDate - date.getDay())
    } else {
        date.setDate(date.getDate() - date.getDay())
    }

    date.setDate(new Date(date.getFullYear(), date.getMonth(), 0).getDate());
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0)
    return getDateTimeFieldValue(date);
}



export const getDateTimeFieldValue = (date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

export const getLocalDate = (str: string): string => {
    const date = new Date(str);
    return date.toDateString();
}