import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {Requests} from '../../api/requests';
import {getMonthEndDate, getMonthStartDate} from '../../utils/common/datetime';

export default async function viewCalendarLoader({params}) {

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listCalendarItems,
        data: {
            starting_at: getMonthStartDate(),
            ending_at: getMonthEndDate(),
            doc_type: ['task', 'event', 'note', 'goal']
        }
    });

    return {items: response.data?.items || [], routeContext: params.context, error: response.error};
}