import React, {PropsWithChildren} from 'react';
import {Modal} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import Icon from '../Icon/Icon';

import './PickModal.scss'

export type Props = {
    show?: boolean
    title?: string
    onClose?: () => void
} & DefaultProps;

export default function PickModal({show, title, children, onClose}: PropsWithChildren<Props>) {
    return (
        <>
            {show && <Modal show={show} contentClassName="pick-modal bg-dark" onHide={onClose} centered >
                <Modal.Header className="d-flex justify-content-between text-white" style={{borderBottomColor: '#333333'}}>
                    <span>{title}</span>
                    <Icon type="ti-close" onClick={onClose} style={{cursor: 'pointer'}} />
                </Modal.Header>
                <Modal.Body >
                    {children}
                </Modal.Body>
            </Modal>}
        </>
    )
}