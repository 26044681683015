import React, {useEffect } from 'react';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {END_OF_DATA_POINTER, WorldClock} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import useListItems, {Pointer} from '../../../../../hooks/useListItems';
import WorldClockCard from '../../../../Common/Cards/WorldClockCard/WorldClockCard';
import useAppTitle from '../../../../../hooks/useAppTitle';


export const listWorldClocks = async (options: {query?: string, collection?: string, pointer?: Pointer}) => {
    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listWorldClocks,
        data: {
            collection: options.collection,
            query: options.query,
            pointer: options.pointer?.value,
            limit: options.pointer?.limit,
        }
    });

    if (response.error || !response.data) {
        throw response.error;
    }

    return response.data?.items ? {...response.data} : {items: []} as unknown as {items: any[], pointer: string|null};
}

export default function ListWorldClocksScreen() {

    const {items, query, onSearchChange, onLoadMore, RenderedErrors, hasErrors, pointer, isLoadingMore} = useListItems<WorldClock>({listCallback: listWorldClocks});


    const showLoadMore = pointer.value !== END_OF_DATA_POINTER && pointer.value;

    useAppTitle('World Clocks');

    useEffect(() => {
        onLoadMore();
    }, [])


    return (
        <Row className="m-0 application pb-2">
            <Col xs={12}>
                <SearchBar
                    onClick={(query) => onSearchChange(query)}
                    onBlur={(query) => onSearchChange(query)}
                    className="mt-3 mb-1 default-background" query={query}
                />
                {hasErrors && <RenderedErrors className="mb-2 default-background" />}
                {isLoadingMore && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}
                {items.map(worldClock => <WorldClockCard key={worldClock._id} worldClock={worldClock} className="mb-2" />)}
                {!isLoadingMore && items.length < 1 && <div className="text-white mb-2">No world clocks were found...</div>}
                {showLoadMore && <PrimaryButton spinnerVariant="light" className="w-100" variant="outline-primary" isProcessing={isLoadingMore} onClick={onLoadMore}>Load More</PrimaryButton>}
            </Col>
        </Row>
    );
}