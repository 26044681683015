import {Outlet, useLocation } from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {
    routeBookmarksEdit, routeBookmarksList,
    routeEntityTaxonomiesEdit,
    routeEntityTaxonomiesList,
} from '../../../routes';
import LayoutNav, {LayoutNavItem} from '../../Common/LayoutNav/LayoutNav';
import ApplicationLayout from './ApplicationLayout';

export type Props = DefaultProps;


export function getNavItems(path: string): LayoutNavItem[] {
    return [
        {
            to: routeBookmarksList(),
            text: 'Bookmarks'
        },
        {
            to: routeEntityTaxonomiesList(path, 'collection'),
            text: 'Collections'
        },
        {
            to: routeEntityTaxonomiesList(path, 'tag'),
            text: 'Tags'
        },
    ]
}

export function getContentAwareNavLink(url?: string): LayoutNavItem {
    if (url?.includes(routeEntityTaxonomiesList(url,'collection')) || url?.includes(routeEntityTaxonomiesEdit(url,'collection'))) {
        return {text: 'Add Collection', to: routeEntityTaxonomiesEdit(url,'collection')};
    }

    if (url?.includes(routeEntityTaxonomiesList(url,'tag')) || url?.includes(routeEntityTaxonomiesEdit(url,'tag')) ) {
        return {text: 'Add Tag', to: routeEntityTaxonomiesEdit(url,'tag')};
    }

    return {text: 'Add Bookmark', to: routeBookmarksEdit()};
}

export default function BookmarksLayout({style, className}: Props) {
    const {pathname} = useLocation();
    const [addItem, setAddItem] = useState(getContentAwareNavLink(pathname));

   useEffect(() => {
       setAddItem(getContentAwareNavLink(pathname));
   }, [pathname]);

    return (
        <ApplicationLayout className={classnames(className)} style={{...style}}>
            <LayoutNav addItem={addItem} items={getNavItems(pathname)} />
            <Outlet />
        </ApplicationLayout>)
}