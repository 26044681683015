import {Taxonomy} from '../../db/types';

export const filterTaxonomiesByEntities = (taxonomies: Taxonomy[], entities: string[]) => {

    return taxonomies.filter(t => {
        const taxonomyEntities = t.entities || [];

        if (taxonomyEntities.length < 1) {
            taxonomyEntities.push('all')
        }

        for (const e of taxonomyEntities) {
            if (entities.includes(e)) {
                return true;
            }
        }

        return false;
    })
}