import React, {MouseEvent, useCallback, useMemo, useState} from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import {Goal, Task} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import {lightOrDark} from '../../../../utils/common/lightOrDark';
import Icon from '../../Icon/Icon';
import PanelButton from '../CardPanelButton/CardPanelButton';
import CardPanel from '../CardPanel/CardPanel';
import {useNavigate} from 'react-router-dom';
import {routeGoalsDelete, routeGoalsEdit, routeTasksDelete, routeTasksEdit} from '../../../../routes';
import configs from '../../../../configs';
import Title from '../ItemCard/Title/Title';
import {getFormattedDate} from '../../../../utils/common/datetime';

export type Props = {
    goal: Goal,
    onClick?: (event: MouseEvent<HTMLElement>) => void
    panel?: boolean,
} & DefaultProps;



export default function GoalCard({goal, onClick, className, style, panel = true}: Props) {
    const [showPanel, setShowPanel] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const navigate = useNavigate();


    const bgColor = goal.collection?.color || configs.cards.defaultColor;
    const color = 'text-white';


    const onEdit = useCallback( (goal) => {
        navigate(routeGoalsEdit(goal._id));
    }, []);

    const onDelete = useCallback((task) => {
        navigate(routeGoalsDelete(task._id));
    }, [])

    return (
        <Card
            id={goal._id}
            className={classnames('item-card position-relative pb-2 default-background border-0', color, className)}
            style={{cursor: 'pointer', ...style}}
            onClick={onClick}
            onMouseEnter={() => setShowPanel(true)}
            onMouseLeave={() => setShowPanel(false)}
        >
            {panel && showPanel && <CardPanel>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    setCollapsed(!collapsed)
                }}>
                    <Icon type="gg-merge-horizontal" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onEdit(goal);
                }}>
                    <Icon type="gg-pen" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onDelete(goal);
                }}>
                    <Icon type="gg-trash" className={color}  />
                </PanelButton>
            </CardPanel> }
            <div className="d-flex align-items-center">
                {!collapsed && <div className="d-flex pt-3 justify-content-center align-items-center" style={{width: '15%'}}>
                    <Icon type="ti-star" style={{fontSize: '2.5rem'}} />
                </div>}
                <Card.Body className={classnames({"ps-0": !collapsed})}>
                    <Title className="m-0 p-0" text={goal.name} />
                </Card.Body>
            </div>


            {!collapsed && <div className="item-card-footer d-flex flex-column">
                <div className="d-flex align-self-end" style={{width: '85%', position: 'relative'}} >
                    <div className="d-flex align-items-center">
                        <Icon type="ti-package" className="me-1" />
                        <span>{goal.collection?.name || 'Unknown'}</span>
                    </div>
                    <span className="mx-2" dangerouslySetInnerHTML={{__html: '&centerdot;'}} />
                    <div className="d-flex">
                        {goal.starting_at && getFormattedDate(goal.starting_at, true)}
                    </div>
                    <div className="ms-4 float-end  rounded-circle position-absolute " style={{backgroundColor: bgColor, width: "20px", height: "20px", right: "5px"}} />
                </div>
            </div>}
        </Card>
    );
}