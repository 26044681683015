import {Requests} from '../../api/requests';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {sendRequest} from '../../api/frontend/api';


export default function makeListTaxonomiesLoader(entity: string) {
    return async function listTaxonomiesLoader({params, request}) {
        const collectionsResponse = await sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.listTaxonomies,
            data: {
                type: params.type
            }
        });

        return {taxonomies: collectionsResponse.data?.taxonomies || [], type: params.type , entity: entity, routeContext: params.context, collectionEntities: ['all', entity], error: collectionsResponse.error};
    }
}
