import React, {PropsWithChildren} from 'react';
import DefaultProps from '../../../types/DefaultProps';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Icon from '../Icon/Icon';
import {getTimestampId} from '../../../utils/common/getTimestampId';

export type Props = {
    placement: 'left'|'top'|'right'|'bottom',
} & DefaultProps;

export default function HelpTooltip({placement, children, className, style}: PropsWithChildren<Props>) {
    return (
        <OverlayTrigger
            overlay={<Tooltip id="button-tooltip">
                {children}
            </Tooltip>}
            placement="top"
            delay={{ show: 250, hide: 400 }}
        >
            <Icon
                type="ti-help-alt"
                className={className}
                style={style}
            />
        </OverlayTrigger>
    );
}