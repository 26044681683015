import {useEffect} from 'react';
import {Response} from '../db/types';
import * as devConsole from '../utils/common/devConsole';
import {Environments, getEnv} from '../utils/common/env';
import {getWorker} from '../utils/pwa/workers';

export default function useResponseListener(callback: (message: Response) => void) {
    useEffect(() => {
        if (getEnv() === Environments.EXTENSION) {
            chrome.runtime.onMessage.addListener((message) => {
                devConsole.log('in use response listener')
                callback(message);
            });
        } else {
            const worker = getWorker();
            worker.addEventListener('message', (message) => {
                callback(message.data);
            })
        }
    }, []);
}