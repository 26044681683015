import React, {useEffect} from 'react';
import {ResponseError, Taxonomy, TaxonomyType} from '../../../../../db/types';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import TaxonomyCard from '../../../../Common/Cards/TaxonomyCard/TaxonomyCard';
import useTaxonomyFeature from '../../../../../hooks/useTaxonomyFeature';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function ListTaxonomiesScreen() {
    const {taxonomies, type, error, entity, collectionEntities} = useLoaderData() as {taxonomies: Taxonomy[], type: TaxonomyType, error: ResponseError, entity: string, collectionEntities: string[]};
    const {query, onSearchChange, isLoading, taxonomies: stateTaxonomies, hasErrors, RenderedErrors, addError} = useTaxonomyFeature(taxonomies || [], type, collectionEntities);

    useAppTitle(`${type.toUpperCase()[0]}${type.slice(1)}`);

    useEffect(() => {
        if (error) {
            addError(error);
        }

    }, [error])

    return (
        <Row className="m-0 application pb-2 mt-1">
            <Col xs={12}>
                <SearchBar
                    onClick={(query) => onSearchChange(query)}
                    onBlur={(query) => onSearchChange(query)}
                    className="mt-2 mb-2 default-background" query={query}
                />

                {hasErrors && <RenderedErrors className="my-2" />}
                {stateTaxonomies.map(taxonomy => <TaxonomyCard key={taxonomy._id} taxonomy={taxonomy} entity={entity} className="mb-2" />)}
                {!isLoading && stateTaxonomies.length < 1 && <div className="text-white mb-2">No {type}s were found...</div>}
                {isLoading && stateTaxonomies.length < 1 && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}
            </Col>
        </Row>
    );
}