import React from 'react'
import Icon from '../../../Icon/Icon';
import DefaultProps from '../../../../../types/DefaultProps';
import classnames from 'classnames';
import {maxChars} from '../../NoteCard/NoteCard';

export type Props = {
    maxLength?: number
    text?: string
} & DefaultProps

export default function CollectionMeta({className, style, text, maxLength = 20}: Props) {
    return (
        <div className={classnames("d-flex align-items-center", className)} style={style}>
            <Icon type="ti-package" className="me-1" />
            <span>{text ? maxChars(text, maxLength) : 'Unknown'}</span>
        </div>
    )
}