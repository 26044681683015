import {Request, Response} from '../../../db/types';
import * as devConsole from '../../../utils/common/devConsole';
import {logRuntimeLastError} from '../../../utils/common/devConsole';
import delay from '../../../utils/common/delay';
import {Requests} from '../../requests';

export default class ChromeExtension {
    sendRequest(request: Request<any>): Promise<Response> {
        return new Promise((resolve) => {
            chrome.runtime.sendMessage(request, function(response) {
                devConsole.log('send request response');
                devConsole.dir(response);

                if ([
                    Requests.listGoals,
                    Requests.listTasks,
                    Requests.listBookmarks,
                    Requests.listWorldClocks,
                    Requests.listNotes,
                    Requests.listEvents,
                ].includes(request.type)) {
                    delay(600).then(() => {
                        logRuntimeLastError();
                        resolve(response)
                    }).catch(error => console.error(error))
                } else {
                    logRuntimeLastError();
                    resolve(response)
                }
            });

            logRuntimeLastError();
        })
    }
}