import React, { useCallback, useState, MouseEvent} from 'react';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import {Taxonomy} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import {lightOrDark} from '../../../../utils/common/lightOrDark';
import Icon from '../../Icon/Icon';
import PanelButton from '../CardPanelButton/CardPanelButton';
import CardPanel from '../CardPanel/CardPanel';
import configs from '../../../../configs';
import {useNavigate} from 'react-router-dom';
import {
    routeEntityTaxonomiesDelete,
    routeEntityTaxonomiesEdit,
    routeTaxonomiesDelete,
    routeTaxonomiesEdit
} from '../../../../routes';
import {getFormattedDate} from '../../../../utils/common/datetime';

export type Props = {
    taxonomy: Taxonomy,
    panel?: boolean,
    onClick?: (event: MouseEvent<HTMLElement>) => void,
    entity?: string,
} & DefaultProps;



export default function TaxonomyCard({taxonomy, className, style, onClick, panel = true, entity = "notes"}: Props) {
    const [showPanel, setShowPanel] = useState(false);
    let bgColor = '';
    let color = 'text-white';
    let bg = 'default-background border-0';

    if (taxonomy.type == 'collection') {
        bg = '';
        bgColor = taxonomy.color || configs.cards.defaultColor;
        color = 'text-white';
    }

    const navigate = useNavigate();

    const onEdit = useCallback( (taxonomy) => {
        //just use navigate;
        navigate(routeEntityTaxonomiesEdit(`/${entity}`, taxonomy.type, taxonomy._id));
    }, [taxonomy, entity]);

    const onDelete = useCallback((taxonomy) => {
        //just use navigate it's the same app;
        navigate(routeEntityTaxonomiesDelete(`/${entity}`,taxonomy._id));
    }, [taxonomy, entity])

    return (
        <Card
            id={taxonomy._id}
            className={classnames('taxonomy-card position-relative pb-2', color, bg, className)}
            style={{cursor: 'pointer', backgroundColor: bgColor, ...style}}
            onMouseEnter={() => setShowPanel(true)}
            onMouseLeave={() => setShowPanel(false)}
            onClick={onClick}
        >
            {panel && showPanel && <CardPanel>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onEdit(taxonomy);
                }}>
                    <Icon type="gg-pen" className={color} />
                </PanelButton>
                <PanelButton onClick={(event) => {
                    event.stopPropagation();
                    onDelete(taxonomy);
                }}>
                    <Icon type="gg-trash" className={color}  />
                </PanelButton>
            </CardPanel> }
            <div className="d-flex align-items-center px-3">
                <Card.Body className="pt-3 pb-2 px-0">
                    <Card.Title className="m-0 p-0 px-0">{taxonomy.name}</Card.Title>
                </Card.Body>
            </div>

            <div className="taxonomy-card-footer px-3">
                <div className="d-flex align-self-start justify-content-start" style={{position: 'relative', left: '-3px'}}>
                    <div className="d-flex">
                        <Icon type="gg-box" />
                        <span>{taxonomy.type[0].toUpperCase()+taxonomy.type.slice(1) || 'Unknown'}</span>
                    </div>
                    <span className="mx-2" dangerouslySetInnerHTML={{__html: '&centerdot;'}} />
                    <div className="d-flex">
                        {getFormattedDate(taxonomy._id)}
                    </div>
                </div>
            </div>
        </Card>
    );
}