const configs = {
    dropzone: {
        maxFileSize: 1000000,
        maxFiles: 1,
        accept: {
            'image/jpeg': [],
            'image/png': [],
            'image/tiff': [],
            'image/svg': [],
            'image/svg+xml': [],
            'image/gif': [],
        },
    },

    application: {
        cardsPerPage: 10,
        collectionCardsPerPage: 50,
        searchCardsPerPage: 10,
    },

    validation: {
        docs: {
            name: {
                maxLength: 2048,
                minLength: 3,
            },

            maxAttachmentsSize:  102400, //100KB
            maxDocumentSize: 102400, //100KB
        },
    },


    cards: {
        defaultColor: '#000000',
    },

    sanitize: {
        description: {
            allowedTags: ["b", "i", "em", "strong", "a", "p", "s", "ul", "li", "ol", "u"],
            allowedAttributes: { a: ["href", "target"] }
        },

        excerpt: {
            allowedTags: [],
            allowedAttributes: [],
        }
    },

    cloudAddress: 'https://ark2.buhalbu.com',
    productPageAddress: 'https://sashevuchkov.gumroad.com/l/tyezv',
}

export default configs;