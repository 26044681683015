import React, {PropsWithChildren, useState} from 'react';
import {Button} from 'react-bootstrap';
import Icon from '../../Icon/Icon';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';

export type Props = {
    onClick: () => void,
    show?: boolean,
    label: string,
} & DefaultProps;

export default function OptionalField({className, style, children, onClick, label, show}: PropsWithChildren<Props>) {
    return (
        <div className={classnames("optional-field d-inline-block me-1 my-2", className, {"w-100": show})} style={style}>
            {show ? children :
                <Button variant="outline-primary" size="sm" style={{fontSize: '0.8rem'}} onClick={onClick}>
                <Icon type="ti-plus" /> {label}
            </Button>
            }
        </div>
    );
}