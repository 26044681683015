import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from './getTimestampId';
import {BACKGROUND_DOCUMENT_ID, DEFAULT_COLLECTION_ID, DEFAULT_NOTE_ID} from './constants';
import {getDataUrlFromFirstAttachment} from './base64';
import configs from '../../configs';
import {Request} from '../../db/types';


export async function getBackground() {
    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getBackground,
        data: {
            _id: BACKGROUND_DOCUMENT_ID,
        }
    });

    if (response?.data?._attachments) {
        return getDataUrlFromFirstAttachment(response.data._attachments);
    }
}

export async function openTasks() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openTasks,

    });
}

export async function openCalendar() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openCalendar,

    });
}

export async function openGoals() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openGoals,

    });
}

export async function openNotebook() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openApplication,

    });
}

export async function openBookmarks() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openBookmarks,

    });
}

export async function openWorldClocks() {
    await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.openWorldClocks,
    });
}

export function makeUpsertCollectionRequestObject(): Request<any> {
    return {
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.insertTaxonomy,
        data: {
            _id: DEFAULT_COLLECTION_ID,
            type: 'collection',
            color: configs.cards.defaultColor,
            name: 'General',
            modified_at: new Date().toISOString(),
        }
    };
}

export function makeUpsertDemoNoteRequestObject(): Request<any>  {
    return {
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.insertNote,
        data: {
            _id: DEFAULT_NOTE_ID,
            collection: {_id: DEFAULT_COLLECTION_ID, name: 'General', type: 'collection', color: configs.cards.defaultColor},
            name: 'Demo Note',
            modified_at: new Date().toISOString(),
            content: "This a demo note and it's purpose is to showcase to that feature."
        }

    }
}