import React, {useEffect} from 'react'
import {createBrowserRouter , RouterProvider} from 'react-router-dom'

import ListNotesScreen from '../../components/Screen/Application/Notebook/ListNotesScreen/ListNotesScreen';
import EditNoteScreen from '../../components/Screen/Application/Notebook/EditNoteScreen/EditNoteScreen';
import MessageScreen from '../../components/Screen/Application/Common/MessageScreen/MessageScreen';
import ViewNoteScreen from '../../components/Screen/Application/Notebook/ViewNoteScreen/ViewNoteScreen';
import ListTaxonomiesScreen from '../../components/Screen/Application/Taxonomies/ListTaxonomiesScreen/ListTaxonomiesScreen';
import NotebookLayout from '../../components/Layout/Pwa/NotebookLayout';
import EditTaxonomyScreen from '../../components/Screen/Application/Taxonomies/EditTaxonomyScreen/EditTaxonomyScreen';
import DeleteNoteScreen from '../../components/Screen/Application/Notebook/DeleteNoteScreen/DeleteNoteScreen';
import ApplicationError from '../../components/Common/ApplicationError/ApplicationError';
import DeleteTaxonomyScreen from '../../components/Screen/Application/Taxonomies/DeleteTaxonomyScreen/DeleteTaxonomyScreen';
import deleteTaxonomiesLoader from '../../loaders/taxonomies/deleteTaxonomiesLoader';
import viewNotesLoader from '../../loaders/notes/viewNotesLoader';
import editNotesLoader from '../../loaders/notes/editNotesLoader';
import deleteNotesLoader from '../../loaders/notes/deleteNotesLoader';
import listNotesLoader from '../../loaders/notes/listNotesLoader';
import BookmarksLayout from '../../components/Layout/Pwa/BookmarksLayout';
import deleteBookmarkLoader from '../../loaders/bookmarks/deleteBookmarkLoader';
import editBookmarkLoader from '../../loaders/bookmarks/editBookmarkLoader';
import EditBookmarkScreen from '../../components/Screen/Application/Bookmarks/EditBookmarkScreen/EditBookmarkScreen';
import ListBookmarksScreen from '../../components/Screen/Application/Bookmarks/ListBookmarksScreen/ListBookmarksScreen';
import DeleteBookmarkScreen from '../../components/Screen/Application/Bookmarks/DeleteBookmarkScreen/DeleteBookmarkScreen';
import WorldClocksLayout from '../../components/Layout/Pwa/WorldClocksLayout';
import ListWorldClocksScreen
  from '../../components/Screen/Application/WorldClocks/ListWorldClocksScreen/ListWorldClocksScreen';
import DeleteWorldClocksScreen
  from '../../components/Screen/Application/WorldClocks/DeleteWorldClocksScreen/DeleteWorldClocksScreen';
import EditWorldClocksScreen
  from '../../components/Screen/Application/WorldClocks/EditWorldClocksScreen/EditWorldClocksScreen';
import deleteWorldClockLoader from '../../loaders/worldClocks/deleteWorldClockLoader';
import editWorldClockLoader from '../../loaders/worldClocks/editWorldClockLoader';
import ListTasksScreen from '../../components/Screen/Application/Tasks/ListTasksScreen/ListTasksScreen';
import listTasksLoader from '../../loaders/tasks/listTasksLoader';
import deleteTasksLoader from '../../loaders/tasks/deleteTasksLoader';
import DeleteTaskScreen from '../../components/Screen/Application/Tasks/DeleteTaskScreen/DeleteTaskScreen';
import editTasksLoader from '../../loaders/tasks/editTasksLoader';
import EditTaskScreen from '../../components/Screen/Application/Tasks/EditTaskScreen/EditTaskScreen';
import TasksLayout from '../../components/Layout/Pwa/TasksLayout';
import ViewCalendarScreen from '../../components/Screen/Application/Calendar/ViewCalendarScreen/ViewCalendarScreen';
import CalendarLayout from '../../components/Layout/Pwa/CalendarLayout';
import EventsLayout from '../../components/Layout/Pwa/EventsLayout';
import listEventsLoader from '../../loaders/events/listEventsLoader';
import deleteEventsLoader from '../../loaders/events/deleteEventsLoader';
import editEventsLoader from '../../loaders/events/editEventsLoader';
import EditEventScreen from '../../components/Screen/Application/Events/EditEventScreen/EditEventScreen';
import DeleteEventsScreen from '../../components/Screen/Application/Events/DeleteEventScreen/DeleteEventsScreen';
import ListEventsScreen from '../../components/Screen/Application/Events/ListEventsScreen/ListEventsScreen';
import makeEditTaxonomiesLoader from '../../loaders/taxonomies/editTaxonomiesLoader';
import makeListTaxonomiesLoader from '../../loaders/taxonomies/listTaxonomiesLoader';
import listBookmarksLoader from '../../loaders/bookmarks/listBookmarksLoader';
import GoalsLayout from '../../components/Layout/Pwa/GoalsLayout';
import ListGoalsScreen from '../../components/Screen/Application/Goals/ListGoalsScreen/ListGoalsScreen';
import listGoalsLoader from '../../loaders/goals/listGoalsLoader';
import deleteGoalsLoader from '../../loaders/goals/deleteGoalsLoader';
import DeleteGoalScreen from '../../components/Screen/Application/Goals/DeleteGoalScreen/DeleteGoalScreen';
import editGoalsLoader from '../../loaders/goals/editGoalsLoader';
import EditGoalScreen from '../../components/Screen/Application/Goals/EditGoalScreen/EditGoalScreen';
import viewCalendarLoader from '../../loaders/calendar/viewCalendarLoader';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {Requests} from '../../api/requests';
import contextLoader from '../../loaders/context/contextLoader';
import overViewGoalsLoader from '../../loaders/goals/overviewGoalsLoader';
import OverviewGoalScreen from '../../components/Screen/Application/Goals/OverviewGoalScreen/OverviewGoalScreen';

const router = createBrowserRouter([
  {
    path: undefined,
    element: <CalendarLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {
        loader: viewCalendarLoader,
        path: '/',
        element:  <ViewCalendarScreen />
      },

      {
        loader: async ({params}) => {
          return params;
        },
        path: '/messages/:messageId?',
        element: <MessageScreen />,
      },
      {
        path: '/options',
        element:  <ListNotesScreen />
      },
    ]
  },
  {
    loader: contextLoader,
    path: "/notes/:context",
    element: <NotebookLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [

      {loader: listNotesLoader,
        path: '/notes/:context/list',
        element:  <ListNotesScreen />
      },
      {
        loader: deleteNotesLoader,
        path: '/notes/:context/delete/:_id',
        element: <DeleteNoteScreen />,
      },
      {loader: editNotesLoader,
        path: '/notes/:context/edit/:type/:_id?/',
        element: <EditNoteScreen />,
      },
      {loader: viewNotesLoader,
        path: '/notes/:context/view/:_id',
        element: <ViewNoteScreen />,
      },

      {loader: makeListTaxonomiesLoader('notes'),
        path: '/notes/:context/taxonomies/list/:type',
        element:  <ListTaxonomiesScreen />
      },
      {loader: makeEditTaxonomiesLoader('notes'),
        path: '/notes/:context/taxonomies/edit/:type/:_id?',
        element: <EditTaxonomyScreen />,
      },

      {loader: deleteTaxonomiesLoader,
        path: '/notes/:context/taxonomies/delete/:_id?',
        element: <DeleteTaxonomyScreen />,
      },

      {
        loader: async ({params}) => {
          return {...params, entity: 'notes'};
        },
        path: '/notes/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  },

  {
    loader: contextLoader,
    path: '/calendar/:context',
    element: <CalendarLayout />,
    errorElement: <ApplicationError />,
    children: [
      {
        loader: viewCalendarLoader,
        path: '/calendar/:context/view',
        element:  <ViewCalendarScreen />
      },
    ]
  },
  {
    path: '/bookmarks',
    element: <BookmarksLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {loader: listBookmarksLoader,
        path: '/bookmarks/:context/list',
        element:  <ListBookmarksScreen />
      },

      {
        loader: deleteBookmarkLoader,
        path: '/bookmarks/:context/delete/:_id',
        element: <DeleteBookmarkScreen />,
      },
      {loader: editBookmarkLoader,
        path: '/bookmarks/:context/edit/:_id?/',
        element: <EditBookmarkScreen />,
      },

      {loader: makeListTaxonomiesLoader('bookmarks'),
        path: '/bookmarks/:context/taxonomies/list/:type',
        element:  <ListTaxonomiesScreen />
      },
      {loader: makeEditTaxonomiesLoader('bookmarks'),
        path: '/bookmarks/:context/taxonomies/edit/:type/:_id?',
        element: <EditTaxonomyScreen />,
      },

      {loader: deleteTaxonomiesLoader,
        path: '/bookmarks/:context/taxonomies/delete/:_id?',
        element: <DeleteTaxonomyScreen />,
      },

      {
        loader: async ({params}) => {
          return {...params, entity: 'bookmarks'};
        },
        path: '/bookmarks/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  },

  {
    loader: contextLoader,
    path: '/tasks/:context',
    element: <TasksLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {loader: listTasksLoader,
        path: '/tasks/:context/list',
        element:  <ListTasksScreen />
      },

      {
        loader: deleteTasksLoader,
        path: '/tasks/:context/delete/:_id',
        element: <DeleteTaskScreen />,
      },
      {loader: editTasksLoader,
        path: '/tasks/:context/edit/:type/:_id?/',
        element: <EditTaskScreen />,
      },

      {loader: makeListTaxonomiesLoader('tasks'),
        path: '/tasks/:context/taxonomies/list/:type',
        element:  <ListTaxonomiesScreen />
      },
      {loader: makeEditTaxonomiesLoader('tasks'),
        path: '/tasks/:context/taxonomies/edit/:type/:_id?',
        element: <EditTaxonomyScreen />,
      },

      {loader: deleteTaxonomiesLoader,
        path: '/tasks/:context/taxonomies/delete/:_id?',
        element: <DeleteTaxonomyScreen />,
      },

      {
        loader: async ({params}) => {
          return {...params, entity: 'tasks'};
        },
        path: '/tasks/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  },

  {
    loader: contextLoader,
    path: '/events/:context',
    element: <EventsLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {loader: listEventsLoader,
        path: '/events/:context/list',
        element:  <ListEventsScreen />
      },

      {
        loader: deleteEventsLoader,
        path: '/events/:context/delete/:_id',
        element: <DeleteEventsScreen />,
      },
      {loader: editEventsLoader,
        path: '/events/:context/edit/:_id?',
        element: <EditEventScreen />,
      },

      {loader: makeListTaxonomiesLoader('events'),
        path: '/events/:context/taxonomies/list/:type',
        element:  <ListTaxonomiesScreen />
      },
      {loader: makeEditTaxonomiesLoader('events'),
        path: '/events/:context/taxonomies/edit/:type/:_id?',
        element: <EditTaxonomyScreen />,
      },

      {loader: deleteTaxonomiesLoader,
        path: '/events/:context/taxonomies/delete/:_id?',
        element: <DeleteTaxonomyScreen />,
      },

      {
        loader: async ({params}) => {
          return {...params, entity: 'events'};
        },
        path: '/events/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  },

  {
    loader: contextLoader,
    path: '/goals/:context',
    element: <GoalsLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {loader: listGoalsLoader,
        path: '/goals/:context/list',
        element:  <ListGoalsScreen />
      },

      {loader: overViewGoalsLoader,
        path: '/goals/:context/overview',
        element:  <OverviewGoalScreen />
      },

      {
        loader: deleteGoalsLoader,
        path: '/goals/:context/delete/:_id',
        element: <DeleteGoalScreen />,
      },
      {loader: editGoalsLoader,
        path: '/goals/:context/edit/:_id?/',
        element: <EditGoalScreen />,
      },

      {loader: makeListTaxonomiesLoader('goals'),
        path: '/goals/:context/taxonomies/list/:type',
        element:  <ListTaxonomiesScreen />
      },
      {loader: makeEditTaxonomiesLoader('goals'),
        path: '/goals/:context/taxonomies/edit/:type/:_id?',
        element: <EditTaxonomyScreen />,
      },

      {loader: deleteTaxonomiesLoader,
        path: '/goals/:context/taxonomies/delete/:_id?',
        element: <DeleteTaxonomyScreen />,
      },

      {
        loader: async ({params}) => {
          return {...params, entity: 'goals'};
        },
        path: '/goals/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  },

  {
    path: '/world-clocks',
    element: <WorldClocksLayout style={{height: '100%', minHeight: '100vh'}} />,
    errorElement: <ApplicationError />,
    children: [
      {
        path: '/world-clocks/:context/list',
        element:  <ListWorldClocksScreen />
      },
      {
        loader: deleteWorldClockLoader,
        path: '/world-clocks/:context/delete/:_id',
        element: <DeleteWorldClocksScreen />,
      },
      {
        loader: editWorldClockLoader,
        path: '/world-clocks/:context/edit/:_id?/',
        element: <EditWorldClocksScreen />,
      },
      {
        loader: async ({params}) => {
          return params;
        },
        path: '/world-clocks/:context/messages/:messageId?',
        element: <MessageScreen />,
      },
    ]
  }
])

export default function App () {

  useEffect(() => {
    sendRequest({
      _id: getTimestampId(),
      generic: 'request',
      type: Requests.initAccount,
    }).then(response => {
      console.log("INIT ACCOUNT");
      console.log(response);
    }).catch(error => {
      console.error(error);
    });
  }, [])

  return <RouterProvider router={router} />
}
