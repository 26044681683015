import {Requests} from '../../api/requests';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {sendRequest} from '../../api/frontend/api';

export default async function editWorldClockLoader({params}) {

    if (!params._id) {
        return {bookmark: null, routeContext: params.context};
    }


    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getWorldClock,
        data: {_id: params._id}
    });

    return {worldClock: response.data || null, routeContext: params.context, error: response.error};
}