import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import {Button, Card, Form, FormControl, FormGroup, FormLabel, InputGroup} from 'react-bootstrap';
import CollectionField from '../Form/CollectionField/CollectionField';
import ColorField from '../Form/ColorField/ColorField';
import configs from '../../../configs';
import TagsField from '../Form/TagsField/TagsField';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {Attachment, Bookmark, Taxonomy} from '../../../db/types';
import DefaultProps from '../../../types/DefaultProps';
import classnames from 'classnames';
import {getFaviconAttachment} from '../../../utils/extention/favicon';
import {Requests} from '../../../api/requests';
import {sendRequest} from '../../../api/frontend/api';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {sanitizeDescription} from '../../../utils/common/sanitize';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {getDataUrlFromAttachment} from '../../../utils/common/base64';
import Icon from '../Icon/Icon';
import HelpTooltip from '../HelpTooltip/HelpTooltip';
import {getModifiedAt} from '../../../utils/common/datetime';

export type EditData = {
    _id?: string,
    name: string,
    url: string,
    attachment?: Attachment,
    modified_at?: string,
    collection?: Taxonomy,

}


export type Props = {
    bookmark?: Bookmark,
    onSubmit?: (bookmark: Bookmark) => void,
    onCancel?: () => void,
} & DefaultProps;

export default function BookmarkEdit({bookmark, onCancel, onSubmit, className, style}: Props) {
    const [data, setData] = useState<EditData>({name: '', url: ''});
    const [isSaving, setIsSaving] = useState(false);
    const [collection, setCollection] = useState<Taxonomy|undefined>();
    const [tags, setTags] = useState<Taxonomy[]>([]);
    const [description, setDescription] = useState<string>('');

    const {addError, RenderedErrors, clearErrors} = useResponseErrors();

    useEffect(() => {
        if (!bookmark) {
            return;
        }

        const newData = {...bookmark} as EditData;
        if (bookmark._attachments) {
            const values = Object.values(bookmark._attachments) as Attachment[];

            if (values[0]) {
                newData.attachment = {...values[0], data: getDataUrlFromAttachment(values[0] as Attachment)}
            }
        }

        setData(newData);

        if (bookmark.description) {
            setDescription(bookmark.description);
        }

        if (bookmark.collection) {
            setCollection(bookmark.collection);
        }

        if (bookmark.tags) {
            setTags(bookmark.tags);
        }

    }, [bookmark])

    const onFormSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        clearErrors();
        event.preventDefault();
        setIsSaving(false)

        const modifiedAt = getModifiedAt(data?._id);
        const attachments: Attachment[] = [];

        const attachment = await getFaviconAttachment(data.url);

        if (attachment) {
            attachments.push(attachment);
        }

        const newBookmark: Bookmark = {
            _id: data._id || getTimestampId(),
            name: data.name,
            description: sanitizeDescription(description),
            modified_at: modifiedAt,
            url: data.url,
            collection: {...data.collection, ...collection} as Taxonomy,
            tags: tags,
        }

        const response = await sendRequest({
            _id: getTimestampId(),
            type: Requests.upsertBookmark,
            generic: 'request',
            data: newBookmark,
            attachments: attachments
        });

        setIsSaving(false);

        if (response?.data?.status === 'success') {
            setData({name: '', url: ''});
            setDescription('');
        }

        if (response?.error) {
            addError(response.error);
            return;
        }

        if (attachment) {
            newBookmark._attachments = {favicon: attachment};
        }

        onSubmit && onSubmit(newBookmark);

    }, [data, collection, tags, description]);

    const onFormCancel = useCallback(() => {
        if (isSaving) {
            return;
        }

        onCancel && onCancel();
    }, [])


    const onUrlBlur = async () => {
        if (!data.url) {
            return;
        }

        const attachment = await getFaviconAttachment(data.url);
        if (attachment) {
            setData({...data, attachment: attachment});
        }
    }


    return (
        <Card className={classnames("bg-transparent border-0", className)} style={style}>
            <Card.Body>
                <Form onSubmit={onFormSubmit}>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                        <div className="p-0 m-0 me-4 dropzone d-flex justify-content-center align-items-center" style={{width: '123px', height: '140px', textAlign: 'center'}}>
                            <div className="d-flex align-items-center justify-content-center default-background" style={{width: '48px', height: '48px', textAlign: 'center'}}>
                                {data.attachment ? <img src={data.attachment.data} style={{objectFit: 'cover', width: '100%', height: '100%'}} /> : 'No Icon'}
                            </div>

                        </div>

                        <div className="w-100">
                            <FormGroup className="mb-2">
                                <FormLabel htmlFor="bookmark_name" className="text-white">Name</FormLabel>
                                <FormControl
                                    className="default-background"
                                    maxLength={configs.validation.docs.name.maxLength}
                                    minLength={configs.validation.docs.name.minLength}
                                    type="text"
                                    name="bookmark_name"
                                    value={data.name}
                                    onChange={(event) => setData((data) => ({...data, name: event.target.value}))}
                                    required={true}
                                />
                            </FormGroup>

                            <CollectionField onChange={setCollection} value={collection} entities={['all', 'bookmarks']} />
                        </div>
                    </div>

                    <ColorField value={collection?.color || configs.cards.defaultColor} onChange={() => {}} readOnly={true} className="my-2" />
                    <FormGroup>
                        <FormLabel htmlFor="bookmark_url" className="text-white">
                            URL <HelpTooltip placement="top" className="ms-2 default-background" style={{cursor: 'pointer'}}>If you have never opened the website before, please open it by clicking the button</HelpTooltip>
                        </FormLabel>
                        <InputGroup>
                            <FormControl onBlur={onUrlBlur} type="url" className="default-background" name="bookmark_url" value={data.url} onChange={(event) => setData((data) => ({...data, url: event.target.value}))} required={true} />
                            <Button className="border-0 default-background" onClick={() => data.url && window.open(data.url, '_blank')}>
                                <Icon type="ti-new-window" />
                            </Button>
                        </InputGroup>

                    </FormGroup>
                    <TagsField tags={tags} setTags={setTags} className="mt-2" />

                    <div style={{backgroundColor: '333333'}}>
                        <PrimaryButton className="w-100 mt-3" isProcessing={isSaving} type="submit">
                            Save
                        </PrimaryButton>
                        <Button variant="outline-primary text-white" className="w-100 mt-2" onClick={onFormCancel} disabled={isSaving}>Cancel</Button>
                    </div>

                    <RenderedErrors className="mt-2" />
                </Form>
            </Card.Body>
        </Card>
    );
}