import ChromeExtension from './ChromeExtension/ChromeExtension';
import ChromePwa from './ChromePwa/ChromePwa';


export default function makeApi() {

    if (typeof chrome.runtime !== 'undefined') {
        return new ChromeExtension()
    }

    return new ChromePwa()
}
