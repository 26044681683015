import React, {useCallback, useMemo} from 'react';
import {Goal, GoalStatus} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import {Alert, Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import {sanitizeContent} from '../../../../utils/common/sanitize';
import classnames from 'classnames';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import {Requests} from '../../../../api/requests';
import {sendRequest} from '../../../../api/frontend/api';
import {getTimestampId} from '../../../../utils/common/getTimestampId';
import {useResponseErrors} from '../../../../hooks/useResponseErrors';

export type Props = {
  goal: Goal,
  onBack: () => void
  onChange?: () => void
} & DefaultProps

export default function OverviewGoal({goal, style, className, onBack, onChange}: Props) {
    const {addError, clearErrors, RenderedErrors} = useResponseErrors();

    const sanitizedDescription = useMemo(() => {
        if (goal?.content) {
            return sanitizeContent(goal.content);
        }

        return '';
    }, [goal?.content]);

    const onChangeStatus = useCallback((status: GoalStatus) => {
        clearErrors();
        goal.status = status;
        goal.completed_at = new Date().toISOString();

        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.upsertGoal,
            data: goal,
        }).then((response) => {
            if (response.error) {
                addError(response.error)
            } else {
                onChange && onChange();
                onBack();

            }
        });
    }, [goal, addError, clearErrors])


    return (
        <Card className={classnames("bg-transparent border-0 w-100 p-0", className)} style={style}>
            <Card.Body>
                <Form>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                        {goal._attachments && <div className="d-flex align-items-center justify-content-center me-4 dropzone" style={{width: '160px', height: '140px', textAlign: 'center'}}>
                            <img src={getDataUrlFromFirstAttachment(goal._attachments)} style={{objectFit: 'cover', width: '100%', height: '100%'}} />
                        </div>}

                        <div className="w-100">
                            <FormGroup className="mb-2">
                                <FormLabel htmlFor="name" className="text-white">Goal</FormLabel>
                                <FormControl type="text" className="default-background"  name="name" defaultValue={goal.name} readOnly={true}  />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="collection" className="text-white">Collections</FormLabel>
                                <Form.Select required={true} name="collection" className="default-background" defaultValue={goal.collection?._id || goal?.collection?._id}>
                                    <option>
                                        {goal.collection?.name}
                                    </option>
                                </Form.Select>
                            </FormGroup>

                        </div>
                    </div>

                    <FormGroup className="d-flex justify-content-between align-items-center my-2">
                        <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                        <FormControl
                            type="color"
                            name="color"
                            disabled={true}
                            value={goal.collection?.color}
                            onChange={() => {}}
                        />
                    </FormGroup>

                    <FormGroup className="my-2">
                        <FormLabel htmlFor="starting_at" className="text-white">{!!goal.ending_at  && goal.ending_at !== goal.starting_at ? 'Starting At' : 'Due Date'}</FormLabel>
                        <div className="d-flex align-items-center">
                            <FormControl className="default-background" type="datetime-local" name="starting_at" defaultValue={goal.starting_at}  />
                        </div>
                    </FormGroup>

                    {!!goal.ending_at  && goal.ending_at !== goal.starting_at && <FormGroup className="my-2">
                        <FormLabel htmlFor="ending_at" className="text-white">Ending At</FormLabel>
                        <div className="d-flex align-items-center">
                            <FormControl className="default-background" type="datetime-local" name="ending_at" defaultValue={goal.ending_at}  readOnly={true}  />
                        </div>
                    </FormGroup>}

                    {!!goal.content && <FormGroup>
                        <FormLabel htmlFor="description" className="text-white">Description</FormLabel>
                        <div className="bg-black p-2 text-white" dangerouslySetInnerHTML={{__html: sanitizedDescription}} />
                    </FormGroup>}

                    <div style={{backgroundColor: '333333'}} className="mt-3">
                        <PrimaryButton
                            className="my-3 w-100"
                            onClick={(event) =>{
                                event.preventDefault();
                                onChangeStatus(goal.status === GoalStatus.IN_PROGRESS ? GoalStatus.COMPLETED : GoalStatus.IN_PROGRESS);
                            }}
                        >
                            {goal.status === GoalStatus.IN_PROGRESS ? `Mark As Completed` : `Restore`}
                        </PrimaryButton>

                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    </div>
                </Form>
            </Card.Body>
        </Card>
    );
}