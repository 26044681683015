import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Response, Task} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeTasksList} from '../../../../../routes';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import TaskCard from '../../../../Common/Cards/TaskCard/TaskCard';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteTaskScreen() {
    const response = useLoaderData() as Response & {routeContext: string};
    const navigate = useNavigate();

    useAppTitle('Tasks', 'Delete Task');

    const onDelete = useCallback(async () => {
       const r = await sendRequest({
           _id: getTimestampId(),
           generic: 'request',
           type: Requests.removeTask,
           data: {_id: response.data?._id}
       });

       return {response: r, route: routeTasksList(response.routeContext)}
    }, [response]);

    const onCancel = useCallback(() => {
        navigate(routeTasksList(response.routeContext));
    }, [response]);

    return (
        <DeleteScreen
            title="Delete Task"
            onDelete={onDelete}
            onCancel={onCancel}
            text="Are you sure you want to delete this task?"
        >
            {response.data && <TaskCard task={response.data as Task} panel={false} />}
        </DeleteScreen>
    );
}