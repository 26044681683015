import React, {useCallback, useMemo} from 'react';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import {Task, TaskStatus} from '../../../../db/types';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';
import {sanitizeContent} from '../../../../utils/common/sanitize';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import {Requests} from '../../../../api/requests';
import {sendRequest} from '../../../../api/frontend/api';
import {getTimestampId} from '../../../../utils/common/getTimestampId';
import {useResponseErrors} from '../../../../hooks/useResponseErrors';

export type Props = {
    task: Task,
    onBack: () => void
    onChange?: () => void,
} & DefaultProps;

export default function ViewTask({task, onBack, className, style, onChange}: Props) {
    const {addError, clearErrors, RenderedErrors} = useResponseErrors();

    const sanitizedDescription = useMemo(() => {
        if (task?.content) {
            return sanitizeContent(task.content);
        }

        return '';
    }, [task?.content]);

    const onChangeStatus = useCallback((status: TaskStatus) => {
        clearErrors();
        task.status = status;
        task.completed_at = new Date().toISOString();

        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.upsertTask,
            data: task,
        }).then((response) => {
            if (response.error) {
                addError(response.error)
            } else {
                onChange && onChange();
                onBack();

            }
        });
    }, [task, addError, clearErrors])

    return(
        <Card className={classnames("bg-dark w-100 p-3  border-0", className)} style={style}>
            <Card.Body>
                <Form>
                    <div className="d-flex justify-content-start align-items-center mb-3">
                        {task._attachments && <div className="d-flex align-items-center justify-content-center me-4 dropzone" style={{width: '160px', height: '140px', textAlign: 'center'}}>
                            <img src={getDataUrlFromFirstAttachment(task._attachments)} style={{objectFit: 'cover', width: '100%', height: '100%'}} />
                        </div>}

                        <div className="w-100">
                            <FormGroup className="mb-2">
                                <FormLabel htmlFor="name" className="text-white">Title</FormLabel>
                                <FormControl type="text" className="default-background" name="name" defaultValue={task.name} readOnly={true}  />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel htmlFor="collection" className="text-white">Collections</FormLabel>
                                <Form.Select required={true} className="default-background" name="collection" defaultValue={task.collection?._id || task?.collection?._id}>
                                    <option>
                                        {task.collection?.name}
                                    </option>
                                </Form.Select>
                            </FormGroup>

                        </div>
                    </div>

                    <FormGroup className="d-flex justify-content-between align-items-center my-2">
                        <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                        <FormControl
                            type="color"
                            name="color"
                            disabled={true}
                            value={task.collection?.color}
                            onChange={() => {}}
                        />
                    </FormGroup>

                    <FormGroup className="my-2">
                        <FormLabel htmlFor="starting_at" className="text-white">{!!task.ending_at  && task.ending_at !== task.starting_at ? 'Starting At' : 'Date'}</FormLabel>
                        <div className="d-flex align-items-center">
                            <FormControl className="default-background" type="datetime-local" name="starting_at" defaultValue={task.starting_at}  />
                        </div>
                    </FormGroup>

                    {!!task.ending_at  && task.ending_at !== task.starting_at && <FormGroup className="my-2">
                        <FormLabel htmlFor="ending_at" className="text-white">Ending At</FormLabel>
                        <div className="d-flex align-items-center">
                            <FormControl className="default-background" type="datetime-local" name="ending_at" defaultValue={task.ending_at}  readOnly={true}  />
                        </div>
                    </FormGroup>}

                    {!!task.content && <FormGroup>
                        <FormLabel htmlFor="description" className="text-white">Description</FormLabel>
                        <div className="bg-black p-2 default-background" dangerouslySetInnerHTML={{__html: sanitizedDescription}} />
                    </FormGroup>}

                    <div style={{backgroundColor: '333333'}} className="mt-3">
                        <PrimaryButton
                            className="my-3 w-100"
                            onClick={(event) =>{
                                event.preventDefault();
                                onChangeStatus(task.status === TaskStatus.IN_PROGRESS ? TaskStatus.COMPLETED : TaskStatus.IN_PROGRESS);
                            }}
                        >
                            {task.status === TaskStatus.IN_PROGRESS ? `Mark As Completed` : `Restore`}
                        </PrimaryButton>
                        <Button
                            variant="outline-primary"
                            className="w-100"
                            onClick={onBack}
                        >
                            Back
                        </Button>
                    </div>
                    <RenderedErrors className="mt-2" />
                </Form>
            </Card.Body>
        </Card>
    )
}