import React, {PropsWithChildren, MouseEvent} from 'react';
import DefaultProps from '../../../../types/DefaultProps';
import classnames from 'classnames';
import {Button} from 'react-bootstrap';

export type Props = {
    onClick?: (event: MouseEvent<HTMLButtonElement>) => void,
} & DefaultProps

export default function CardPanelButton({className, style, children, onClick}: PropsWithChildren<Props>) {
    return (
        <Button
            className={classnames('d-flex align-items-center border-0 bg-transparent px-2', className)}
            style={{height: '30px', ...style}}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}