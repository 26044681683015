import React, {FormEvent, useCallback, useEffect, useRef, useState} from 'react';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import useResponseListener from '../../../../../hooks/useResponseListener';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {Attachment, DocType, Taxonomy} from '../../../../../db/types';
import {getDataUrlFromAttachment} from '../../../../../utils/common/base64';
import {useLoaderData, useNavigate} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import Dropzone from '../../../../Common/Dropzone/Dropzone';
import {Doc as DocEntity} from '../../../../../db/types';
import SimpleEditor from '../../../../Common/SimpleEditor/SimpleEditor';
import {routeNotesList, routeMessages} from '../../../../../routes';
import configs from '../../../../../configs';
import * as devConsole from '../../../../../utils/common/devConsole';
import {sanitizeContent} from '../../../../../utils/common/sanitize';
import convertToHTML from 'draftjs-to-html';
import useAppTitle from '../../../../../hooks/useAppTitle';
import TagsField from '../../../../Common/Form/TagsField/TagsField';
import CollectionField from '../../../../Common/Form/CollectionField/CollectionField';
import {getModifiedAt} from '../../../../../utils/common/datetime';
import {useResponseErrors} from '../../../../../hooks/useResponseErrors';




export type EditData = {
    _id?: string,
    name: string,
    attachment?: Attachment,
    color: string,
    created_at?: string,
    modified_at?: string,
    collection?: Taxonomy,

}

export default function EditNoteScreen() {
    const [data, setData] = useState<EditData>({name: '', color: '#000000'});
    const [isSaving, setIsSaving] = useState(false);
    const [collection, setCollection] = useState<Taxonomy|undefined>();
    const [tags, setTags] = useState<Taxonomy[]>([]);
    const [content, setContent] = useState<string>('');
    const [clipContent, setClipContent] = useState<string>('');

    const navigate = useNavigate();
    const {note, editorType, routeContext} = useLoaderData() as {note: DocEntity, editorType: DocType, routeContext: string};
    const {clearErrors, addError, RenderedErrors} = useResponseErrors();

    const isImageCard = editorType === 'image';

    useAppTitle('Notes', note ? 'Edit Note' : 'New Note');

    useResponseListener((message) => {

        if (message.type === Requests.loadNoteEditAttachment) {
            if (message.data && Array.isArray(message.data?.attachments) && message.data.attachments.length > 0) {
                setData((data) => {
                    if (!message.data) {
                        return data;
                    }

                    return {...data, attachment: message.data.attachments[0]}
                });
            }

            if (message.data?.content) {
                setContent(message.data?.contetn);
                setClipContent(message.data.content);
            }
        }
    })

    useEffect(() => {
        if (note) {
            setContent(note.content || '');
            return;
        }

        devConsole.log('cache hit')
        sendRequest({
            _id: getTimestampId(),
            generic: 'request',
            type: Requests.hitCachedContext
        }).then((response) => {
            if (!response.data) {
                return;
            }

            devConsole.log('in the cache hit ')
            devConsole.dir(response);

            setClipContent(response.data?.content || '');
            setContent(response.data?.content || '');
            setData({...data, ...response.data});
        }).catch((error: any) => console.dir(error));
    }, []);

    useEffect(() => {
        if (!note) {
            return;
        }

        const newData = {...note} as EditData;
        if (note._attachments) {
            const values = Object.values(note._attachments) as Attachment[];

            if (values[0]) {
                newData.attachment = {...values[0], data: getDataUrlFromAttachment(values[0] as Attachment)}
            }
        }

        setData(newData);

        if (note.content) {
            setContent(note.content);
        }

        if (note.collection) {
            setCollection(note.collection);
        }

        if (note.tags) {
            setTags(note.tags);
        }

    }, [note])


    const onSubmit = useCallback(async (event: FormEvent<HTMLFormElement>) => {
        clearErrors();
        event.preventDefault();
        setIsSaving(false)
        const createdAt = data.created_at || new Date().toISOString();
        const modifiedAt = getModifiedAt(data?._id);

        const attachments: Attachment[] = [];

        if (data.attachment) {
            attachments.push(data.attachment);
        }


        const response = await sendRequest({
            _id: getTimestampId(),
            type: Requests.upsertNote,
            generic: 'request',
            data: {
               _id: data._id || getTimestampId(),
                name: data.name,
                content: sanitizeContent(content),
                color: data.color,
                created_at: createdAt,
                modified_at: modifiedAt,
                collection: {...data.collection, ...collection},
                tags: tags,
                type: editorType,
                context: note?.context || routeContext,
            },
            attachments: attachments
        });

        setIsSaving(false);
        if (response?.data?.status === 'success') {
            setData({name: '', color: '#000000'});
            setContent('');
            navigate(routeMessages('successful-note-saving', note?.context || routeContext))
        }

        if (response.error) {
            addError(response.error)
        }

    }, [data, collection, tags, content, clearErrors, addError, note]);

    const onCancel = useCallback(() => {
        if (isSaving) {
            return;
        }

        navigate(routeNotesList(note?.context || routeContext));
    }, [note, routeContext])

    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <Card className="bg-transparent border-0 w-100">
                <Card.Body>
                    <Form onSubmit={onSubmit}>
                        <div className="d-flex justify-content-start align-items-center mb-3">
                            {isImageCard && <Dropzone onFileReady={(file) => {
                                setData((data) => ({...data, attachment: {...file}}));
                            }}
                                      className="p-0 m-0 me-4 edit-dropzone"
                            >
                                <div className="d-flex align-items-center justify-content-center" style={{width: '123px', height: '140px', textAlign: 'center'}}>
                                    {data.attachment ? <img src={data.attachment.data} style={{objectFit: 'cover', width: '100%', height: '100%'}} /> : 'Upload'}
                                </div>
                            </Dropzone>}

                            <div className="w-100">
                                <FormGroup className="mb-2">
                                    <FormLabel htmlFor="note_name" className="text-white">Name</FormLabel>
                                    <FormControl
                                        maxLength={configs.validation.docs.name.maxLength}
                                        minLength={configs.validation.docs.name.minLength}
                                        className="default-background"
                                        type="text" name="note_name"
                                        value={data.name}
                                        onChange={(event) => setData((data) => ({...data, name: event.target.value}))}
                                        required={true}
                                    />
                                </FormGroup>
                                <CollectionField onChange={setCollection} value={collection} entities={['all', 'notes']} />

                            </div>
                        </div>

                        <FormGroup className="d-flex justify-content-between align-items-center my-2">
                            <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                            <FormControl
                                type="color"
                                name="color"
                                readOnly={true}
                                value={collection?.color || configs.cards.defaultColor}
                                onChange={() => {
                                    //don't change anything;
                                }}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormLabel className="text-white">Content</FormLabel>
                            <SimpleEditor
                                className="default-background text-white"
                                value={note?.content || clipContent || ''}
                                onChange={(state) => {
                                    setContent(() => convertToHTML(state));
                                }}
                            />
                        </FormGroup>

                        <TagsField tags={tags} setTags={setTags} className="mt-2" />

                        <div style={{backgroundColor: '333333'}}>
                            <PrimaryButton className="w-100 mt-3" isProcessing={isSaving} type="submit">
                                Save
                            </PrimaryButton>
                            <Button variant="outline-primary text-white" className="w-100 mt-2" onClick={onCancel} disabled={isSaving}>Cancel</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
            <RenderedErrors className="mt-2" />
        </div>
    );
}