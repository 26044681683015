import React, {useMemo} from 'react';
import {Event} from '../../../../db/types'
import DefaultProps from '../../../../types/DefaultProps';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import {getDataUrlFromFirstAttachment} from '../../../../utils/common/base64';
import {sanitizeContent} from '../../../../utils/common/sanitize';
import classnames from 'classnames';

export type Props = {
    event: Event
    onBack: () => void,
    onChange?: () => void,
} & DefaultProps;

export default function ViewEvent({event, onBack, className, style}: Props) {
    const sanitizedDescription = useMemo(() => {

        if (event?.content) {
            return sanitizeContent(event.content);
        }

        return '';

    }, [event?.content]);


    return (
       <Card className={classnames("bg-dark w-100 p-3  border-0", className)} style={style}>
           <Card.Body>
               <Form>
                   <div className="d-flex justify-content-start align-items-center mb-3">
                       {event?._attachments && <div className="d-flex align-items-center justify-content-center me-4 dropzone" style={{width: '160px', height: '140px', textAlign: 'center'}}>
                           <img src={getDataUrlFromFirstAttachment(event._attachments)} style={{objectFit: 'cover', width: '100%', height: '100%'}} />
                       </div>}

                       <div className="w-100">
                           <FormGroup className="mb-2">
                               <FormLabel htmlFor="name" className="text-white">Name</FormLabel>
                               <FormControl type="text" name="name" className="default-background" defaultValue={event.name} readOnly={true}  />
                           </FormGroup>
                           <FormGroup>
                               <FormLabel htmlFor="collection" className="text-white">Collections</FormLabel>
                               <Form.Select required={true} name="collection" className="default-background" defaultValue={event.collection?._id}>
                                   <option>
                                       {event.collection?.name}
                                   </option>
                               </Form.Select>
                           </FormGroup>

                       </div>
                   </div>

                   <FormGroup className="d-flex justify-content-between align-items-center my-2">
                       <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                       <FormControl
                           type="color"
                           name="color"
                           disabled={true}
                           value={event.collection?.color}
                           onChange={() => {}}
                       />
                   </FormGroup>

                   <FormGroup className="my-2">
                       <FormLabel htmlFor="starting_at" className="text-white">{!!event.ending_at && event.ending_at !== event.starting_at ?  'Starting At' : 'Date'}</FormLabel>
                       <div className="d-flex align-items-center">
                           <FormControl className="default-background" type="datetime-local" name="starting_at" defaultValue={event.starting_at}  />
                       </div>
                   </FormGroup>

                   {!!event.ending_at && event.ending_at !== event.starting_at && <FormGroup className="my-2">
                       <FormLabel htmlFor="ending_at" className="text-white">Ending At</FormLabel>
                       <div className="d-flex align-items-center">
                           <FormControl className="default-background" type="datetime-local" name="ending_at" defaultValue={event.ending_at}  readOnly={true}  />
                       </div>
                   </FormGroup>}


                   {!!event.location && <FormGroup className="mb-2">
                       <FormLabel htmlFor="location" className="text-white">Location</FormLabel>
                       <FormControl type="text" className="default-background" name="location" defaultValue={event.location} readOnly={true}  />
                   </FormGroup>}

                   {!!event.content && <FormGroup>
                       <FormLabel htmlFor="description" className="text-white">Notes</FormLabel>
                       <div className="bg-black p-2 text-white" dangerouslySetInnerHTML={{__html: sanitizedDescription}} />
                   </FormGroup>}

                   <div style={{backgroundColor: '333333'}} className="mt-3">
                       <Button
                           variant="outline-primary"
                           className="w-100"
                           onClick={onBack}
                       >
                           Back
                       </Button>
                   </div>
               </Form>
           </Card.Body>
       </Card>
   );
}