import React from 'react';
import {Modal} from 'react-bootstrap';
import DefaultProps from '../../../types/DefaultProps';
import {Taxonomy, TaxonomyType} from '../../../db/types';
import TaxonomyPick from '../TaxonomyPick/TaxonomyPick';
import Icon from '../Icon/Icon';

export type Props = {
    show?: boolean
    onCancel?: () => void,
    editorType: TaxonomyType,
    onPick?: (taxonomy: Taxonomy) => void;
    entities?: string[]
} & DefaultProps;

export default function TaxonomyModal({show, onPick, onCancel, editorType, entities = ['all']}: Props) {
    return (
        <>
            {show && <Modal show={show} contentClassName="bg-dark border-0" centered={true}>
                <Modal.Header className="d-flex justify-content-between text-white" style={{borderBottomColor: '#333333'}}>
                    <span>Pick a {editorType}</span>
                    <Icon type="gg-close" onClick={onCancel} style={{cursor: 'pointer'}} />
                </Modal.Header>
                <Modal.Body >
                    <TaxonomyPick editorType={editorType} onPick={onPick} entities={entities} />
                </Modal.Body>
            </Modal>}
        </>
    )
}