import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Taxonomy, TaxonomyType} from '../../../db/types';
import DefaultProps from '../../../types/DefaultProps';
import {Button, Card, Form, FormControl, FormGroup, FormLabel} from 'react-bootstrap';
import configs from '../../../configs';
import {getTimestampId} from '../../../utils/common/getTimestampId';
import {Requests} from '../../../api/requests';
import {sendRequest} from '../../../api/frontend/api';
import classnames from 'classnames';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import {useResponseErrors} from '../../../hooks/useResponseErrors';
import {availableEntities} from '../../../utils/common/constants';
import './TaxonomyEdit.scss'
import {getModifiedAt} from '../../../utils/common/datetime';

export type EditData = {
    _id?: string,
    name: string,
    color: string,
    modified_at?: string,
}

export type Props = {
    taxonomy?: Taxonomy,
    onSubmit?: (taxonomy: Taxonomy) => void,
    onCancel?: () => void,
    editorType: TaxonomyType,
    routeContext: string
} & DefaultProps;

export default function TaxonomyEdit({taxonomy, className, style, editorType, onSubmit, onCancel, routeContext}: Props) {
    const [data, setData] = useState<EditData>({name: '', color: configs.cards.defaultColor});
    const [isSaving, setIsSaving] = useState(false);
    const isCollectionCard = editorType === 'collection';
    const formRef = useRef<HTMLFormElement|null>(null);
    const {hasErrors, clearErrors, addError, RenderedErrors} = useResponseErrors();
    const [entities, setEntities] = useState<string[]>([]);

    useEffect(() => {
        clearErrors();
        if (!taxonomy) {
            return;
        }

        setEntities(!taxonomy.entities || taxonomy.entities.length < 1 ? ['all'] : taxonomy.entities)
        setData({...taxonomy as unknown as EditData});
    }, [taxonomy])

    const onFormSubmit = useCallback(async () => {
        const isValid = formRef.current?.checkValidity();
        clearErrors();
        if (!isValid) {
            formRef.current?.reportValidity();
            return;
        }

        setIsSaving(true)
        const id = getTimestampId();
        const modifiedAt = getModifiedAt(data?._id);

        const t: Taxonomy = {
            _id: data._id || id,
            name: data.name,
            color: data.color,
            modified_at: modifiedAt,
            type: editorType,
            entities: entities.length < 1 ? ['all'] : entities,
            context: taxonomy?.context || routeContext,
        };

        const response = await sendRequest({
            _id: getTimestampId(),
            type: Requests.upsertTaxonomy,
            generic: 'request',
            data: t,
        });

        setIsSaving(false);

        if (response.error) {
            addError(response.error);
            return;
        }


        onSubmit && onSubmit(t);
        setData({name: '', color: configs.cards.defaultColor});
    }, [data, entities, routeContext, taxonomy]);

    const onFormCancel = useCallback(() => {
        if (isSaving) {
            return;
        }

        setData({name: '', color: configs.cards.defaultColor});
        onCancel && onCancel();


    }, [])

    return (
        <div className={classnames("taxonomy-edit d-flex justify-content-start flex-column align-items-center", className)} style={{...style}}>
            <Card className="bg-transparent border-0 w-100">
                <Card.Body className="p-0">
                    {hasErrors && <RenderedErrors className="mb-2" />}
                    <Form ref={formRef} onSubmit={onFormSubmit}>
                        <div className="d-flex justify-content-start align-items-center mb-3">
                            <div className="w-100">
                                <FormGroup className="mb-2">
                                    <FormLabel htmlFor="taxonomy_name" className="text-white">Name</FormLabel>
                                    <FormControl className="default-background" type="text" id="taxonomy_name" value={data.name} onChange={(event) => setData((data) => ({...data, name: event.target.value}))} required={true} placeholder="Enter a name" />
                                </FormGroup>
                            </div>
                        </div>

                        {isCollectionCard && <FormGroup className="d-flex justify-content-between align-items-center my-2">
                            <FormLabel htmlFor="color" className="text-white">Color</FormLabel>
                            <FormControl
                                type="color"
                                id="color"
                                required={true}
                                value={data.color}
                                onChange={(event) => setData((data) => ({...data, color: event.target.value}))}
                                className="text-white"
                            />
                        </FormGroup>}

                        {isCollectionCard && <FormGroup className="mb-2 d-flex flex-column">
                            <Form.Label htmlFor="entities" className="text-white">Entities</Form.Label>
                            {availableEntities.map(entity =>
                                <div key={entity._id} className="mb-3">
                                    <Form.Check type="switch" id={`check-api-${entity._id}`}>
                                        <Form.Check.Input
                                            type="checkbox"
                                            className="bg-secondary border-0"
                                            value={entity._id}
                                            checked={entities.includes(entity._id)}
                                            onChange={(event) => event.target.checked ? setEntities([...entities, event.target.value]) : setEntities(entities.filter(e => e != event.target.value))}
                                        />
                                        <Form.Check.Label className="text-white">{entity.name}</Form.Check.Label>
                                    </Form.Check>
                                </div>
                            )}
                        </FormGroup>}


                        <div style={{backgroundColor: '333333'}}>
                            <PrimaryButton
                                className="w-100 mt-3"
                                isProcessing={isSaving}
                                onClick={onFormSubmit}
                                type="button"
                            >
                                Save
                            </PrimaryButton>
                            <Button variant="outline-primary" className="w-100 mt-2" onClick={onFormCancel} disabled={isSaving}>Cancel</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}