import React, {useEffect, useState} from 'react';
import {Taxonomy, TaxonomyType} from '../../../db/types';
import DefaultProps from '../../../types/DefaultProps';
import TaxonomyCard from '../Cards/TaxonomyCard/TaxonomyCard';
import {Button, Spinner} from 'react-bootstrap';
import useTaxonomyFeature from '../../../hooks/useTaxonomyFeature';
import SearchBar from '../SearchBar/SearchBar';
import Icon from '../Icon/Icon';
import TaxonomyEdit from '../TaxonomyEdit/TaxonomyEdit';

export type Props = {
    onPick?: (taxonomy: Taxonomy) => void,
    editorType: TaxonomyType,
    entities?: string[],
    context?: string,
} & DefaultProps;

export default function TaxonomyPick({onPick, editorType, entities = ['all'], context = 'all'}: Props) {
    const {isLoading, taxonomies, onSearchChange, query, hasErrors, RenderedErrors} = useTaxonomyFeature( [], editorType, entities, context);
    const [isCreating, setIsCreating] = useState(false);

    useEffect(() => {
        onSearchChange("");
    }, []);

    return (<div className="taxonomy-pick">
        {!isCreating && <div className="d-flex align-items-center">
            <SearchBar onClick={(query) => onSearchChange(query)} className="mb-2 bg-black mt-2" query={query} disabled={isCreating} />
            <Button
                variant="dark"
                className="bg-black"
                style={{height: '46px'}}
                onClick={() => setIsCreating(true)}
            >
                <Icon type="gg-add" />
            </Button>
        </div>}

        {isCreating && <TaxonomyEdit routeContext={context} className="w-100 p-0" editorType={editorType} onCancel={() => setIsCreating(false)} onSubmit={(taxonomy) => {
            onPick && onPick(taxonomy)
            setIsCreating(false);
        }} />}
        {hasErrors && <RenderedErrors className="my-2" />}

        {!isCreating && taxonomies.map(taxonomy => <TaxonomyCard key={taxonomy._id} taxonomy={taxonomy} className="mb-2" panel={false} onClick={() => {
            onPick && onPick(taxonomy)
        }} />)}
        {!isCreating && !isLoading && taxonomies.length < 1 && <div className="text-white mb-2">No {editorType}s were found...</div>}
        {!isCreating && isLoading && taxonomies.length < 1 && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}
    </div>);
}