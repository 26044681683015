import {Requests} from '../../api/requests';
import {sendRequest} from '../../api/frontend/api';
import {getTimestampId} from '../../utils/common/getTimestampId';

export default async function deleteTasksLoader({params}) {
    if (!params._id) {
        return {data: null, routeContext: params.context};
    }

    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.getTask,
        data: {_id: params._id}
    });

    return {...response, routeContext: params.context} || {data: null, routeContext: params.context};
}