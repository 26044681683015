//https://saturncloud.io/blog/creating-a-blob-from-a-base64-string-in-javascript/
//https://stackoverflow.com/questions/8593896/chrome-extension-how-to-pass-arraybuffer-or-blob-from-content-script-to-the-bac

import {Attachment, BlobAttachment, Message} from '../../db/types';
import configs from '../../configs';

export function strToObj(attachment: string): Attachment {
    return JSON.parse(attachment);
}

export function objToStr(attachment: Attachment): string {
    return JSON.stringify(attachment);
}


export function base64(attachment: {id: string, data: string, type: string}) {
    const binaryString = atob(attachment.data.split(',')[1]); // Binary data string
    const blob = new Blob([binaryString], { type: attachment.type });

    return {...attachment, data: blob};
}

export async function blobToBase64(attachment: BlobAttachment): Promise<Attachment> {
    const base64:string = await convertBlobToBase64(attachment.data);
    return {...attachment, data: base64}
}

export async function blobToString(attachment: BlobAttachment): Promise<string> {
    const base64:string = await convertBlobToBase64(attachment.data);
    return  JSON.stringify({...attachment, data: base64});
}

export async function stringToBlob(attachment: string) {
    const obj = JSON.parse(attachment);
    return base64(obj);
}


export function convertBlobToBase64(blob): Promise<string> {
    return new Promise(resolve => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;
            resolve(base64data as string);
        };
    });
}

export async function mapAttachments(attachments: Record<string, Attachment>) {
    const keys = Object.keys(attachments);
    const mappedAttachments: Attachment[] = [];
   for (let i = 0; i < keys.length; i++) {
       mappedAttachments.push({
           _id: attachments[keys[i]]._id,
           content_type: attachments[keys[i]].content_type,
           data: `data:${attachments[keys[i]].content_type};base64,${attachments[keys[i]].data}`
       });
   }

   return mappedAttachments;
}

export function getDataUrlFromAttachment(attachment: Attachment) {
  return `data:${attachment.content_type};base64,${attachment.data}`
}

export function getDataUrlFromFirstAttachment(attachments: Record<string, Attachment>) {
    const values = Object.values(attachments);

    if(values[0].data.includes(';base64,')) {
        return values[0].data;
    }

    return `data:${values[0].content_type};base64,${values[0].data}`
}


export function validateBlob(blob: Blob) {
    const errors: Message[] = []

    const allowedTypes = Object.keys(configs.dropzone.accept);

    if (!allowedTypes.includes(blob.type)) {
        errors.push({code: 'unsupported-blob-format', message: `File type must be ${allowedTypes.join(', ')}`});
    }

    if (blob.size > configs.dropzone.maxFileSize) {
        errors.push({code: 'image-too-large', message: `The size is larger than ${blob.size / (1000*1000)}MB`});
    }

    return errors;
}