import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import {Button, Card} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {Response} from '../../../../../db/types';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import {useResponseErrors} from '../../../../../hooks/useResponseErrors';
import useAppTitle from '../../../../../hooks/useAppTitle';

export type Props = {
    title: string,
    text: string,
    onDelete: () => Promise<{response: Response, route: string}>,
    onCancel: () => void,
};

export default function DeleteScreen({title, text, children, onCancel, onDelete}: PropsWithChildren<Props>) {
    const [isDeleting, setIsDeleting] = useState(false);
    const navigate = useNavigate();
    const {addError, hasErrors, clearErrors, RenderedErrors} = useResponseErrors();

    useAppTitle(title);

    const onOwnDelete = useCallback(async () => {
        clearErrors();
        setIsDeleting(true);

        const {response, route} = await onDelete();

        if (response.data?.status === 'success') {
            navigate(route);
            return;
        }

        if (response.error) {
            addError(response.error);
        }

        setIsDeleting(false);
    }, [children]);

    const onOwnCancel = useCallback(() => {
        onCancel();
    }, []);

    return (
        <div className="overlay-index-screen d-flex justify-content-center flex-column align-items-center">
            <Card className="bg-transparent w-100 border-0" style={{minHeight: "100vh"}}>
                <Card.Body>
                    <div className="text-white mb-4">
                        {text}
                    </div>

                    {children}

                    {hasErrors && <RenderedErrors className="mt-2" />}

                    <div className="d-flex justify-content-between mt-4">
                        <PrimaryButton variant="outline-primary" isProcessing={isDeleting} onClick={onOwnDelete}>
                            Delete it
                        </PrimaryButton>
                        <Button variant="primary" onClick={onOwnCancel} disabled={isDeleting}>Keep it</Button>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}