import React, {ChangeEvent, FocusEventHandler, MouseEvent, useState} from 'react';
import classnames from 'classnames';
import DefaultProps from '../../../types/DefaultProps';
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import Icon from '../Icon/Icon';
import './SearchBar.scss'

export type Props = {
    query?: string,
    onChange?: (query: string) => void|Promise<void>
    onClick?: (query: string) => void|Promise<void>
    onBlur?: (query: string) => void|Promise<void>
    disabled?: boolean
} & DefaultProps

export default function SearchBar({query, onChange, style, className, disabled, onClick, onBlur}: Props) {
    const [text, setText] = useState(query || '');

    return (
        <InputGroup  className={classnames('search-bar', className)} style={style}>
            <FormControl value={text} onChange={(event) => {
                setText(event.target.value);
                onChange && onChange(event.target.value);
            }} placeholder="Search" disabled={disabled} onBlur={() => onBlur && onBlur(text)} />
            <InputGroup.Text className="bg-transparent">
                <Button size="sm" variant="outline-primary" className={classnames("px-2 py-1")} onClick={() => onClick && onClick(text)} style={{borderColor: !text ? 'transparent' : 'white'}}>
                    <Icon type="ti-search" />
                </Button>
            </InputGroup.Text>
        </InputGroup>
    );
}
