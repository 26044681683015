import {Requests} from '../../api/requests';
import {getTimestampId} from '../../utils/common/getTimestampId';
import {filterTaxonomiesByEntities} from '../../utils/common/taxonomies';
import {sendRequest} from '../../api/frontend/api';

export default async function listTasksLoader({params}) {
    const collectionsResponse = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listTaxonomies,
        data: {
            type: 'collection'
        }
    });

    return {collections: filterTaxonomiesByEntities( collectionsResponse.data?.taxonomies || [] , ['all', 'tasks']), routeContext: params.context, error: collectionsResponse.error};
}