import React, {useCallback, useEffect } from 'react';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {Bookmark, END_OF_DATA_POINTER, Taxonomy} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import SearchBar from '../../../../Common/SearchBar/SearchBar';
import {Col, Container, Row, Spinner} from 'react-bootstrap';
import {useLoaderData} from 'react-router-dom';
import PrimaryButton from '../../../../Common/PrimaryButton/PrimaryButton';
import useListItems, {Pointer} from '../../../../../hooks/useListItems';
import CollectionSelect from '../../../../Common/CollectionSelect/CollectionSelect';
import BookmarkCard from '../../../../Common/Cards/BookmarkCard/BookmarkCard';
import useAppTitle from '../../../../../hooks/useAppTitle';
import getAppTitle from '../../../../../utils/common/appTitle';


export const listBookmarks = async (options: {query?: string, collection?: string, pointer?: Pointer}) => {
    const response = await sendRequest({
        _id: getTimestampId(),
        generic: 'request',
        type: Requests.listBookmarks,
        data: {
            collection: options.collection,
            query: options.query,
            pointer: options.pointer?.value,
            limit: options.pointer?.limit,
        }
    });

    if (response.error || !response.data) {
        throw response.error;
    }

    return response.data?.items ? {...response.data} : {items: []} as unknown as {items: any[], pointer: string|null};
}

export default function ListBookmarksScreen() {

    const data = useLoaderData() as {collections: Taxonomy[]};
    const {items, collection, query, onCollectionSelectChange, onSearchChange, onLoadMore, RenderedErrors, addError, clearErrors, hasErrors, pointer, setPointer, isLoadingMore} = useListItems<Bookmark>({listCallback: listBookmarks});


    const showLoadMore = pointer.value !== END_OF_DATA_POINTER && pointer.value;

    useAppTitle('Bookmarks');

    useEffect(() => {
        onLoadMore();
    }, [])


    const onItemClick = useCallback((item) => {
        window.open(item.url, '_blank');
    } , []);


    return (

        <Row className="m-0 application pb-2">
            <Col xs={12}>
                <SearchBar
                    onClick={(query) => onSearchChange(query)}
                    onBlur={(query) => onSearchChange(query)}
                    className="mt-3 mb-1 default-background" query={query}
                />
                 <CollectionSelect value={collection} className="mb-2 default-background" onChange={onCollectionSelectChange} collections={data.collections} />
                {hasErrors && <RenderedErrors className="mb-2" />}
                {isLoadingMore && <div className="text-white mb-2 d-flex justify-content-center"><Spinner variant="light" animation="border" size="sm" className="d-block" /></div>}
                {items.map(bookmark => <BookmarkCard key={bookmark._id} bookmark={bookmark} onClick={() => onItemClick(bookmark)} className="mb-2" />)}
                {!isLoadingMore && items.length < 1 && <div className="text-white mb-2">No bookmarks were found...</div>}
                {showLoadMore && <PrimaryButton spinnerVariant="light" className="w-100" variant="outline-primary" isProcessing={isLoadingMore} onClick={onLoadMore}>Load More</PrimaryButton>}
            </Col>
        </Row>

    );
}