import React, {useCallback, useEffect} from 'react';
import {Taxonomy, TaxonomyType} from '../../../../../db/types';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {routeEntityTaxonomiesList, routeMessages, routeTaxonomiesList} from '../../../../../routes';
import classnames from 'classnames';
import TaxonomyEdit from '../../../../Common/TaxonomyEdit/TaxonomyEdit';
import useAppTitle from '../../../../../hooks/useAppTitle';




export default function EditTaxonomyScreen() {
    const navigate = useNavigate();
    const {taxonomy, editorType, entity: itemEntity, routeContext} = useLoaderData() as {taxonomy?: Taxonomy, editorType: TaxonomyType, entity: string, routeContext: string};
    const entity = `${editorType[0].toUpperCase()}${editorType.slice(1)}`
    useAppTitle(`${entity}s`, `${!taxonomy ? 'New' : 'Edit'} ${entity}`);

    useEffect(() => {
        if (!editorType) {
            return;
        }

    }, [editorType]);


    const onSubmit = useCallback(async (taxonomy?: Taxonomy) => {
        if (taxonomy) {
            navigate(routeMessages(`successful-${editorType}-saving`, routeContext, itemEntity));
        }
    }, [editorType, routeContext]);

    const onCancel = useCallback(() => {
        navigate(routeEntityTaxonomiesList(`/${itemEntity}`, editorType || 'collection', routeContext));
    }, [routeContext])

    return (
        <div className={classnames("overlay-index-screen")}>
            <TaxonomyEdit routeContext={routeContext} editorType={editorType} taxonomy={taxonomy} onSubmit={onSubmit} onCancel={onCancel} className="w-100 p-3" style={{minHeight: "100vh"}} />
        </div>
    );
}