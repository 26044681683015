import React, {useCallback} from 'react';
import {useLoaderData, useNavigate} from 'react-router-dom';
import {Doc, Response} from '../../../../../db/types';
import {Requests} from '../../../../../api/requests';
import {sendRequest} from '../../../../../api/frontend/api';
import {getTimestampId} from '../../../../../utils/common/getTimestampId';
import {routeNotesList} from '../../../../../routes';
import DeleteScreen from '../../Common/DeleteScreen/DeleteScreen';
import NoteCard from '../../../../Common/Cards/NoteCard/NoteCard';
import useAppTitle from '../../../../../hooks/useAppTitle';

export default function DeleteNoteScreen() {
    const response = useLoaderData() as Response & {routeContext: string};
    const navigate = useNavigate();

    useAppTitle('Notes', 'Delete Note');

    const onDelete = useCallback(async () => {
       const r = await sendRequest({
           _id: getTimestampId(),
           generic: 'request',
           type: Requests.removeNote,
           data: {_id: response.data?._id}
       });

       return {response: r, route: routeNotesList(response.routeContext)}
    }, [response, response.routeContext]);

    const onCancel = useCallback(() => {
        navigate(routeNotesList(response.routeContext));
    }, []);

    return (
        <DeleteScreen
            title="Delete Note"
            onDelete={onDelete}
            onCancel={onCancel}
            text="Are you sure you want to delete this note?"
        >
            {response.data && <NoteCard card={response.data as Doc} panel={false} />}
        </DeleteScreen>
    );
}