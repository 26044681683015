import {Request, Response} from '../../../db/types';
import  {getWorker} from '../../../utils/pwa/workers';
import {Requests} from '../../requests';
import delay from '../../../utils/common/delay';

export default class ChromePwa {

    sendRequest(request: Request<any>): Promise<Response> {
        return new Promise((resolve) => {

            const worker = getWorker();

            worker.postMessage(request);

            const onReceiveResponse = (message) => {
                console.log('in send request')
                console.dir(message)
                if (message.data._id === request._id) {
                    worker.removeEventListener("message", onReceiveResponse);

                    if ([
                        Requests.listGoals,
                        Requests.listTasks,
                        Requests.listBookmarks,
                        Requests.listWorldClocks,
                        Requests.listNotes,
                        Requests.listEvents,
                    ].includes(request.type)) {
                        delay(600).then(() => {
                            resolve(message.data);
                        }).catch(error => console.error(error))
                    } else {
                        resolve(message.data);
                    }


                }
            }

            worker.addEventListener('message', onReceiveResponse);
        })
    }
}